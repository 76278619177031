var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal-fade" } }, [
    _c(
      "div",
      {
        staticClass: "vue-modal-backdrop",
        on: {
          click: function ($event) {
            if ($event.target !== $event.currentTarget) {
              return null
            }
            return _vm.close.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "div",
          {
            class: ["vue-modal", _vm.modalClass],
            style: "width: " + _vm.width,
            attrs: {
              role: "dialog",
              "aria-labelledby": "modalTitle",
              "aria-describedby": "modalDescription",
            },
          },
          [
            _c(
              "header",
              {
                staticClass: "vue-modal-header text-primary",
                attrs: { id: "modalTitle" },
              },
              [
                _vm._t("header"),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn-close",
                    attrs: { type: "button", "aria-label": "Close modal" },
                    on: { click: _vm.close },
                  },
                  [_c("i", { staticClass: "fas fas fa-times-circle" })]
                ),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "section",
              {
                staticClass: "vue-modal-body",
                attrs: { id: "modalDescription" },
              },
              [_vm._t("body")],
              2
            ),
            _vm._v(" "),
            _c(
              "footer",
              { staticClass: "vue-modal-footer" },
              [
                _vm._t("footer"),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button", "aria-label": "Close modal" },
                    on: { click: _vm.close },
                  },
                  [_vm._v("Close")]
                ),
              ],
              2
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }