<template>
  <div class="composer-container">
    <div v-if="success" class="alert alert-success" role="alert">
      Conditional has been added successfully.<br/>
      <a href="/conditionals">Go Back</a>
      <!-- TODO replace with cake route -->
    </div>
    <template v-else>
      <h2>Conditionals</h2>
      <div>
        <b-form>
          <label class="mr-sm-2" for="title">Title</label>
          <b-form-input
              id="title"
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="title"
          ></b-form-input>
        </b-form>
      </div>

      <br/>

      <h2>Conditions</h2>
      <combination :combinationData="{}" v-model="topCondition" @data-updated="updateTopConditionData" id="parent"></combination>
      <br/>

      <div v-for="(condition, index) in conditions" :key="condition.key">
        <condition :id="condition.data.hasOwnProperty('id') ? condition.data.id : index" :allow-children="true" v-model="condition.data" @data-updated="updateConditionData" @add-button-clicked="updateConditionType"
                   @delete-button-clicked="deleteCondition(index)" v-if="condition.type === 'condition'"></condition>
        <combination :id="condition.data.hasOwnProperty('id') ? condition.data.id : index" v-model="condition.data" @data-updated="updateConditionData" @combination-child-added="addCondition('combination', index)"
                     @delete-button-clicked="deleteCondition(index)" v-else-if="condition.type === 'combination'" :repeatable="true"></combination>
        <div class="ml-5" v-for="(childCondition, i) in condition.children" :key="'child_' + childCondition.key">
          <condition @delete-button-clicked="deleteCondition(index, i)" :id="childCondition.data.hasOwnProperty('id') ? childCondition.data.id : i" :parent-id="index" @data-updated="updateConditionData"
                     v-model="childCondition.data"></condition>
        </div>
      </div>

      <div class="row mb-4 mr-2">
        <div class="col-md-auto">
          <b-button variant="primary" class="btn-lg" @click="addCondition('condition')">
            <i class="fa fa-plus-circle"></i>
          </b-button>
        </div>
      </div>

      <h2>Consequences</h2>
      <div v-for="(consequence, index) in consequences" :key="consequence.type">
        <consequence :id="index" v-model="consequence.data" @data-updated="updateConsequenceData" :consequence-types="consequenceTypes" :allow-children="false"
                     @delete-button-clicked="deleteConsequence(index)"></consequence>
      </div>

      <div class="row mb-4 mr-2">
        <div class="col-md-auto">
          <b-button variant="primary" class="btn-lg" @click="addConsequence()">
            <i class="fa fa-plus-circle"></i>
          </b-button>
        </div>
      </div>

      <section class="composer-section spacer consequences">
        <b-button variant="primary" type="submit" @click="sendForm" :disabled="loading">Save</b-button>
      </section>
    </template>
  </div>
</template>

<script>
import Combination from "./Conditionals/Combination";
import Condition from "./Conditionals/Condition";
import Consequence from "./Conditionals/Consequence";

export default {
  name:       'add-conditional',
  components: {
    Combination,
    Condition,
    Consequence
  },
  props:      {
    conditional:      {
      type: Object,
      default() {
        return {}
      }
    },
    consequenceTypes: {
      type:    Object,
      default: {}
    },
  },
  data() {
    return {
      loading:                 false,
      success:                 false,
      isNew:                   false,
      conditions:              {},
      topCondition:            {logic: 'AND', negate: 0},
      uniqueKeyCondition:      0,
      uniqueKeyChildCondition: 0,
      uniqueKeyConsequence:    0,
      consequences:            [],
      title:                   '',
    }
  },

  created() {
    this.isNew = !Object.keys(this.conditional).length;
    if (!this.isNew) {
      this.title = this.conditional.name;
      if (this.conditional.conditions[0] !== undefined) {
        this.topCondition = this.conditional.conditions[0];
        let i = 0;
        this.conditional.conditions[0].child_conditions.forEach((condition) => {
          let conditionToLoad = {
            'key':      i,
            'type':     'condition',
            'children': [],
            'data':     {
              'id':                 condition.id,
              'logic':              condition.logic,
              'negate':             condition.negate,
              'model':              this.getModelFromCondition(condition),
              'expression':         condition.expression,
              'specification':      this.getSpecificationFromCondition(condition),
              'specificationValue': this.getSpecificationValueFromCondition(condition),
              'entity':             condition.value,
              'property':           condition.association_property,
              'consequenceType':    null,
              'children':           []
            }
          };
          // Make sure newly added items end up at the end
          this.uniqueKeyCondition = Math.max(this.uniqueKeyCondition, condition.id);
          if (condition.children !== undefined) {
            let k = 0;
            let children = [];
            condition.children.forEach((childCondition) => {
              children.push(
                  {
                    'key':      k,
                    'type':     'condition',
                    'children': [],
                    'data':     {
                      'id':                 childCondition.id,
                      'logic':              childCondition.logic,
                      'negate':             childCondition.negate,
                      'model':              this.getModelFromCondition(childCondition),
                      'expression':         childCondition.expression,
                      'specification':      this.getSpecificationFromCondition(childCondition),
                      'specificationValue': this.getSpecificationValueFromCondition(childCondition),
                      'entity':             1,
                      'property':           childCondition.association_property,
                      'consequenceType':    null
                    }
                  }
              );
              k++;
              // Make sure newly added items end up at the end
              this.uniqueKeyChildCondition = Math.max(this.uniqueKeyChildCondition, childCondition.id);
            });
            conditionToLoad.children = children;
          }
          this.conditions[condition.id] = conditionToLoad;
          this.$set(this.conditions, condition.id, this.conditions[condition.id])

          // If we have any existing items, make sure to start at 1 higher
          if (this.uniqueKeyCondition > 0) {
            this.uniqueKeyCondition++;
          }
          if (this.uniqueKeyChildCondition > 0) {
            this.uniqueKeyChildCondition++;
          }

          i++;
        });
      }

      if (this.conditional.consequences !== undefined) {
        let j = 0;

        this.conditional.consequences.forEach((consequence) => {
          let entity;
          switch (consequence.type) {
            case 'AddPromotionMutator':
              entity = consequence['promotion_id'];
              break;
            case 'AddAddonGroupMutator':
              entity = consequence['product_group_id'];
              break;
            case 'AddPromotionGroupMutator':
              entity = consequence['promotion_group_id'];
              break;
            case 'AddAddonGroupCanNotBeCombinedMutator':
              entity = consequence['promotion_group_id'];
              break;
            case 'AddAvailableSubMunicipalityMutator':
            case 'AddUnavailableSubMunicipalityMutator':
              entity = consequence['sub_municipality_id'];
              break;
            case 'AddAvailableMainMunicipalityMutator':
            case 'AddUnavailableMainMunicipalityMutator':
              entity = consequence['main_municipality_id'];
              break;
            case 'AddAvailableProvinceMutator':
            case 'AddUnavailableProvinceMutator':
              entity = consequence['province_id'];
              break;
            case 'AddAvailableRegionMutator':
            case 'AddUnavailableRegionMutator':
              entity = consequence['region_id'];
              break;
            default:
              entity = consequence['product_id'];
              break;
          }

          this.consequences.push({'data': {'type': consequence.type, 'entity': entity}});

          j++;

          // Make sure newly added items end up at the end
          this.uniqueKeyConsequence = Math.max(this.uniqueKeyConsequence, consequence.id);
        });

        const result = this.consequences.reduce((acc, curr) => {
          const {entity, type} = curr.data;
          if (!acc[type]) {
            acc[type] = {type, 'key': entity.type, 'data': {type, entity: []}};
          }
          acc[type].data.entity.push(entity);
          return acc;
        }, {});

        this.consequences = Object.values(result);
        // If we have any existing items, make sure to start at 1 higher
        if (this.uniqueKeyConsequence > 0) {
          this.uniqueKeyConsequence++;
        }
      }
    }
  },
  methods: {
    getModelFromCondition(condition) {
      if (condition === null || condition.property === null) {
        return 'combination';
      }
      if (condition.property.match(/\[(.*?)]/)) {
        return 'Specifications';
      }
      if (condition.property === 'category_key') {
        return 'ProductCategories';
      }
      if (condition.property === 'brand_id') {
        return 'Brands';
      }
      return condition.model;
    },
    getSpecificationFromCondition(condition) {
      return condition && condition.property && condition.property.match(/\[(.*?)]/) ? condition.property.match(/\[(.*?)]/)[1] : null
    },
    getSpecificationValueFromCondition(condition) {
      return condition && condition.property ? condition.value : null;
    },
    getSpecificationValueFromConsequence(consequence) {
      switch (consequence.type) {
        case "AddIncludedAddonMutator":
          return consequence.product_id;
        case "AddAddonGroupMutator":
        case "AddonGroupCanNotBeCombinedMutator":
          return consequence.product_group_id;
        case "AddAvailableProvinceMutator":
        case "AddUnavailableProvinceMutator":
          return consequence.province_id;
        case "AddAvailableRegionMutator":
        case "AddUnavailableRegionMutator":
          return consequence.region_id;
        case "AddAvailableSubMunicipalityMutator":
        case "AddUnavailableSubMunicipalityMutator":
          return consequence.sub_municipality_id;
        case "AddAvailableMainMunicipalityMutator":
        case "AddUnavailableMainMunicipalityMutator":
          return consequence.main_municipality_id;
        case "AddPricingMutator":
          return consequence.pricing_id;
        case "AddPromotionGroupMutator":
          return consequence.promotion_group_id;
        case "AddPromotionMutator":
          return consequence.promotion_id;
        default:
          return this.getSpecificationValueFromCondition(consequence.condition);
      }
    },
    addCondition(rowType, parent) {
      if (rowType === 'combination' && parent !== undefined) {
        let newKey = this.uniqueKeyChildCondition++;
        let condition = this.conditions[parent];
        condition['children'].push({type: 'condition', key: newKey, data: {}});
        this.$set(this.conditions, parent, condition);
      } else {
        let newKey = this.uniqueKeyCondition++;
        this.$set(this.conditions, newKey, {type: rowType, children: [], key: newKey, data: {}});
      }
    },
    updateTopConditionData(index, data) {
      this.topCondition = data;
    },
    updateConditionData(index, data, parentIndex) {
      if (parentIndex === undefined) {
        this.conditions[index]['data'] = data;
        this.$set(this.conditions, index, this.conditions[index]);
      } else {
        this.conditions[parentIndex]['children'][index]['data'] = data;
        this.$set(this.conditions, parentIndex, this.conditions[parentIndex]);
      }
    },
    updateConditionType(type, index) {
      this.conditions[index]['type'] = type;
      this.$set(this.conditions, index, this.conditions[index]);
    },
    updateConsequenceData(index, data) {
      this.consequences[index]['data'] = data;
      this.$set(this.consequences, index, this.consequences[index]);
    },
    deleteCondition(index, child) {
      if (child !== undefined) {
        this.conditions[index]['children'].splice(child, 1);
      } else {
        this.$delete(this.conditions, index);
      }
    },
    addConsequence() {
      this.consequences.push({data: {}});
    },
    deleteConsequence(index) {
      this.$delete(this.consequences, index);
    },
    validateForm() {
      let valid = true;
      if (Object.keys(this.conditions).length === 0) {
        valid = false;
        this.$bvToast.toast('Add some conditions first', {
          title:         'You didn\'t add any condition.',
          variant:       'danger',
          autoHideDelay: 5000,
          appendToast:   true,
        });
      }
      if (Object.keys(this.consequences).length === 0) {
        valid = false;
        this.$bvToast.toast('Add some consequences first', {
          title:         'You didn\'t add any consequences.',
          variant:       'danger',
          autoHideDelay: 5000,
          appendToast:   true,
        });
      }
      if (this.title === '') {
        valid = false;
        this.$bvToast.toast('Title is required', {
          title:         'Please enter a title.',
          variant:       'danger',
          autoHideDelay: 5000,
          appendToast:   true,
        });
      }

      // TODO validate each field separately
      return valid;
    },
    /**
     * Submit form pressed, validation steps here/send data through AJAX/follow-up steps
     */
    sendForm() {
      if (this.validateForm() === false || this.loading) {
        return;
      }
      this.loading = true;
      $.ajax({
        data: {
          'title':        this.title,
          'topCondition': this.topCondition,
          'conditions':   this.conditions,
          'consequences': this.consequences
        },
        type: 'POST',
      }).done(response => {
        this.success = true;
      }).fail((xhr) => {
        let errors = JSON.parse(xhr.responseText);
        if (xhr.status === 0 && xhr.statusText === 'abort') {
          // Call aborted, don't do anything
          return;
        }
        this.$bvToast.toast('Error', {
          title:         'Something went wrong.',
          variant:       'danger',
          autoHideDelay: 5000,
          appendToast:   false,
        })
      }).always(() => {
        this.loading = false;
      });
    },
  }
}
</script>
