<template>
  <div class="col-auto">
    <b-form-group>
      <b-form inline>
        <b-button v-if="showDelete" variant="danger" class="btn-sm delete-row icon-only mr-2" @click="$emit('delete-button-clicked')"><i class="fa fa-minus-circle"></i></b-button>
        <b-form-select
            v-if="mutator === false"
            id="if"
            class="mb-2 mr-sm-2 mb-sm-0"
            :options="models"
            v-model="model"
        ></b-form-select>
        <b-form-select
            v-if="specifications.length > 0 && mutator === false"
            id="specifications"
            class="mb-2 mr-sm-2 mb-sm-0"
            :options="specifications"
            v-model="specification"
        ></b-form-select>
        <b-form-select
            v-if="model !== 'combination' && mutator === false"
            id="if"
            class="mb-2 mr-sm-2 mb-sm-0"
            :options="[{value: 'is', text: 'is'}, {value: 'is not', text: 'is not'}]"
            v-model="expression"
        ></b-form-select>

        <b-form-select
            v-if="entities.length > 0"
            id="entities"
            class="mb-2 mr-sm-2 mb-sm-0"
            :multiple="consequence === true"
            :options="entities"
            v-model="entity"
        ></b-form-select>

        <b-form-input
            v-if="specification"
            id="specification-value"
            class="mb-2 mr-sm-2 mb-sm-0"
            v-model="specificationValue"
        ></b-form-input>
      </b-form>
    </b-form-group>
  </div>
</template>

<script>
import vSelect from 'vue-select';

export default {
  name:       'condition',
  components: {
    'v-select': vSelect
  },
  data() {
    return {
      entities:           [],
      specifications:     [],
      model:              null,
      specification:      null,
      expression:         'is',
      entity:             null,
      specificationValue: null,
      consequenceType:    null,
    }
  },
  props:    {
    id:               {
      required: true
    },
    parentId:         {
      required: false
    },
    showDelete:       {
      type:    Boolean,
      default: true
    },
    consequence:      {
      type:    Boolean,
      default: false
    },
    consequenceTypes: {
      type: Object,
      default() {
        return {}
      }
    },
    value:            {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    // Because reactivity is limited with objects, we do this way to sync data with parent
    condition: function() {
      return {
        'id':                 this.id,
        'model':              this.model,
        'specification':      this.specification,
        'entity':             this.entity,
        'specificationValue': this.specificationValue,
        'expression':         this.expression,
        'consequenceType':    this.consequenceType,
      }
    },
    mutator:   function() {
      return !!this.consequenceType && (this.consequenceType.slice(this.consequenceType.length - 7) === 'Mutator');
    },
    models:    function() {
      let conditionModels = [
          {value: null, text: '--- Please choose ---'},
          {value: 'Brands', text: 'Brand'},
          {value: 'Services', text: 'Service'},
          {value: 'ProductCategories', text: 'Category'},
          {value: 'Products', text:  'Product'},
          {value: 'Propositions', text: 'Proposition'},
          {value: 'Specifications', text: 'Specification'}
      ];
      if (this.consequenceType) {
        conditionModels.push({value: 'Promotions', text: 'Promotions'});
      }
      return conditionModels;
    }
  },
  created() {
    Object.keys(this.value).forEach(key => {
      if (key !== 'id' && this.value[key] !== undefined && this[key] !== undefined) {
        this[key] = this.value[key];
      }
    });
    this.entity = this.value['specificationValue'];
  },
  methods: {
    emitCondition() {
      this.$emit('data-updated', this.id, this.condition, this.parentId);
    }
  },
  watch:   {
    model:              function(val) {
      this.$emit('data-updated', this.id, this.condition, this.parentId);
      this.specifications = [];
      this.entities = [];
      if (val !== 'Specifications') {
        this.specification = null;
      }
      if (val === 'combination') {
        this.$emit('add-button-clicked', 'combination', this.id)
      } else if (val !== undefined && this.model) {
        $.ajax({
          url:  '/conditionals/type-options', // TODO use Cakephp route method
          data: {model: this.model},
          type: 'POST',
        }).done(response => {
          if (val === 'Specifications') {
            this.specifications = response.data;
          } else {
            this.entities = response.data;
          }
        }).fail((xhr) => {
          this.$bvToast.toast('Server Error', {
            title:         'Condition options could not be updated.',
            variant:       'danger',
            autoHideDelay: 5000,
            appendToast:   false,
          })
        });
      }
    },
    specification:      function() {
      this.emitCondition();
    },
    expression:         function() {
      this.emitCondition();
    },
    entity:             function() {
      this.emitCondition();
    },
    specificationValue: function() {
      this.emitCondition();
    },
    consequenceType:    function(val, oldVal) {
      if (val === 'CanNotBeCombinedValidator' || val === 'MustBeCombinedValidator') {
        this.model = 'Products';
        this.expression = 'is';
      } else if (val === 'AddPromotionMutator') {
        this.model = 'Promotions';
        this.expression = 'is';
      } else if (val === 'AddPromotionGroupMutator') {
        this.model = 'PromotionGroups';
        this.expression = 'is';
      } else if (val === 'AddAddonGroupMutator' || val === 'AddonGroupCanNotBeCombinedMutator') {
        this.model = 'AddonGroups';
        this.expression = 'is';
      } else if (val === 'AddIncludedAddonMutator') {
        this.model = 'Addons';
        this.expression = 'is';
      } else if (val === 'AddAvailableRegionMutator' || val === 'AddUnavailableRegionMutator') {
        this.model = 'Regions';
        this.expression = 'is';
      } else if (val === 'AddAvailableProvinceMutator' || val === 'AddUnavailableProvinceMutator') {
        this.model = 'Provinces';
        this.expression = 'is';
      } else if (val === 'AddAvailableMainMunicipalityMutator' || val === 'AddUnavailableMainMunicipalityMutator') {
        this.model = 'MainMunicipalities';
        this.expression = 'is';
      } else if (val === 'AddAvailableSubMunicipalityMutator' || val === 'AddUnavailableSubMunicipalityMutator') {
        this.model = 'SubMunicipalities';
        this.expression = 'is';
      } else if (oldVal) {
        this.model = null;
      }
      this.emitCondition();
    }
  }
}
</script>
