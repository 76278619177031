var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.settings.multiple
      ? _c(
          "div",
          { staticClass: "row" },
          [
            _vm.items.length === 0
              ? _c("input", { attrs: { type: "hidden", name: _vm.field } })
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.items, function (item, itemIndex) {
              return _c("div", { staticClass: "col-sm-12" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: item.id,
                      expression: "item.id",
                    },
                  ],
                  attrs: {
                    type: "hidden",
                    name: _vm.field + "[" + itemIndex + "][id]",
                  },
                  domProps: { value: item.id },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(item, "id", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _vm.settings.locale
                  ? _c("input", {
                      attrs: {
                        type: "hidden",
                        name: _vm.field + "[" + itemIndex + "][locale]",
                      },
                      domProps: { value: _vm.settings.locale },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "row mb-2" }, [
                  _c("div", { staticClass: "col-md-2" }, [
                    item.content_type.indexOf("image") === 0
                      ? _c("img", {
                          staticClass: "image-preview",
                          attrs: {
                            src: _vm.settings.respondUrl + item.hash_id,
                            alt: item.name,
                          },
                        })
                      : _c("span", { staticClass: "file-type" }, [
                          _vm._v(_vm._s(item.extension)),
                        ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-7" }, [
                    _c("strong", [_vm._v(_vm._s(item.name))]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-3 text-right" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-outline-danger btn-xs",
                        attrs: { href: "javascript:;" },
                        on: {
                          click: function ($event) {
                            return _vm.items.splice(itemIndex, 1)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.settings.textRemove))]
                    ),
                  ]),
                ]),
              ])
            }),
          ],
          2
        )
      : _vm.items !== null && _vm.items !== undefined
      ? _c("div", { staticClass: "row mb-2" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.items.id,
                expression: "items.id",
              },
            ],
            attrs: { type: "hidden", name: _vm.field + "[id]" },
            domProps: { value: _vm.items.id },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.items, "id", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _vm.settings.locale
            ? _c("input", {
                attrs: { type: "hidden", name: _vm.field + "[locale]" },
                domProps: { value: _vm.settings.locale },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-2" }, [
            _vm.items.content_type.indexOf("image") === 0
              ? _c("img", {
                  staticClass: "image-preview",
                  attrs: {
                    src: _vm.settings.respondUrl + _vm.items.hash_id,
                    alt: _vm.items.name,
                  },
                })
              : _c("span", { staticClass: "file-type" }, [
                  _vm._v(_vm._s(_vm.items.extension)),
                ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-7" }, [
            _c("strong", [_vm._v(_vm._s(_vm.items.name))]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-md-3 text-right" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-outline-danger btn-xs",
                attrs: { href: "javascript:;" },
                on: {
                  click: function ($event) {
                    _vm.items = null
                  },
                },
              },
              [_vm._v(_vm._s(_vm.settings.textRemove))]
            ),
          ]),
        ])
      : _c("input", { attrs: { type: "hidden", name: _vm.field, value: "" } }),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-sm-12" },
        [
          _c("vue-dropzone", {
            ref: "assetDropzone",
            attrs: {
              id: "dropzone-" + _vm.widgetId,
              options: _vm.settings.dropzone,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }