var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "password-strength-label" }, [
      _vm._v(_vm._s(_vm.label)),
    ]),
    _vm._v(" "),
    _c("div", {
      staticClass: "password-strength-bar",
      class: _vm.passwordClass,
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }