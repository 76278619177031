<template>
    <transition name="modal-fade">
        <div class="vue-modal-backdrop" @click.self="close">
            <div :class="['vue-modal', modalClass]" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription" :style="'width: ' + width">
                <header class="vue-modal-header text-primary" id="modalTitle">
                    <slot name="header">
                    </slot>
                    <button type="button" class="btn-close" @click="close" aria-label="Close modal">
                        <i class="fas fas fa-times-circle"></i>
                    </button>
                </header>

                <section class="vue-modal-body" id="modalDescription">
                    <slot name="body">
                    </slot>
                </section>

                <footer class="vue-modal-footer">
                    <slot name="footer">
                    </slot>
                    <button type="button" class="btn btn-primary" @click="close" aria-label="Close modal">Close</button>
                </footer>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name:    'Modal',
    props: {
        width:      {
            type:    String,
            default: null
        },
        modalClass: {
            type:    String,
            default: null
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>
