<template>
    <tr v-if="resultCount > itemsPerPage">
        <td>
            <ul role="menubar" class="pagination b-pagination">
                <li role="presentation" :class="{disabled: isFirstPage, 'page-item': true}" aria-hidden="true">
                    <span v-if="isFirstPage" role="menuitem" aria-controls="pagination-table" aria-disabled="true" class="page-link disabled">«</span>
                    <button v-else role="menuitem" type="button" class="page-link" @click="setPage(1)">«</button>
                </li>
                <li v-for="pageNumber in totalPages" v-if="Math.abs(pageNumber - currentPage) < (3 + itemsPerPage) || pageNumber === totalPages || pageNumber === 1" role="presentation" :class="getPaginationItemClass(pageNumber)">
                    <button role="menuitemradio" type="button" class="page-link" v-bind:key="pageNumber" @click="setPage(pageNumber)">{{ pageNumber }}</button>
                </li>
                <li role="presentation" :class="{disabled: isLastPage, 'page-item': true}">
                    <span v-if="isLastPage" role="menuitem" aria-controls="pagination-table" aria-disabled="true" class="page-link disabled">»</span>
                    <button v-else role="menuitem" type="button" class="page-link" @click="setPage(totalPages)">»</button>
                </li>
            </ul>
        </td>
    </tr>
</template>

<script>
export default {
    name:     "Pagination",
    props:    {
        resultCount:  {
            type:     Number,
            required: true
        },
        itemsPerPage: {
            type:     Number,
            required: true
        },
        totalPages:   {
            type:     Number,
            required: true
        },
        currentPage:  {
            type:     Number,
            required: true
        },
    },
    computed: {
        isFirstPage: function() {
            return this.currentPage <= 1;
        },
        isLastPage:  function() {
            return this.currentPage === this.totalPages;
        },
    },
    methods:  {
        setPage: function(pageNumber) {
            this.$emit('update:currentPage', pageNumber)
        },
        getPaginationItemClass(pageNumber) {
            return {
                'page-item': true,
                active:      this.currentPage === pageNumber,
                last:        (pageNumber === this.totalPages && Math.abs(pageNumber - this.currentPage) > (3 + this.itemsPerPage)),
                first:       (pageNumber === 1 && Math.abs(pageNumber - this.currentPage) > (3 + this.itemsPerPage))
            }
        }
    }
}
</script>

<style scoped>

</style>
