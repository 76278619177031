import 'bootstrap';
import 'select2';
import flatpickr from "flatpickr";
import rangePlugin from "flatpickr/dist/plugins/rangePlugin";
import Sortable from 'sortablejs';
import 'summernote/dist/summernote-bs4';

const slugify = require('slugify');

export default class UI {
    static initialize() {
        let $container = $('body');
        UI.initializeContainer($container);
    }

    /**
     * Initialize a container
     * @param {jQuery} $container
     */
    static initializeContainer($container) {
        // UI.initializeSelects($container); // TODO move select2 fields to Vue or initialize Vue for specific parts only, not the whole layout
        UI.initializeNavbar();
        UI.initializeDatepicker($container);
        UI.initializeNumberInputDecimals($container);
        UI.initializeSlugify($container);
        UI.initializeSortable($container);
        UI.initializeSummernote($container);
    }

    /**
     * Initialize datepickers
     *
     * @param $container
     */
    static initializeDatepicker($container) {
        $container.find('[data-datepicker]').each(function() {
            let $this   = $(this);
            let options = $this.data('datepicker');

            // Link current input to a separate input for the 'to'-date
            if (typeof options.rangeInputTo !== 'undefined') {
                options.plugins = [new rangePlugin({input: options.rangeInputTo})];
            }

            flatpickr($this, options);
        });
    }

    /**
     * Initialize number-input-fields and enforce the decimals to X
     *
     * @param $container
     */
    static initializeNumberInputDecimals($container) {
        $container.find('[data-enforce-decimals]').on('keyup', function() {
            if ($(this).data('enforce-decimals') != null) {
                let separator = '.';
                let decimal   = parseInt($(this).data('enforce-decimals')) || 0;
                let val       = $(this).val();
                if (decimal > 0) {
                    let splitVal = val.split(separator);
                    if (splitVal.length === 2 && splitVal[1].length > decimal) {
                        // Trim everything after 'separator' more than X characters long
                        $(this).val(splitVal[0] + separator + splitVal[1].substr(0, decimal));
                    }
                } else {
                    let splitVal = val.split(separator);
                    if (splitVal.length > 1) {
                        // Trim everything after 'separator'
                        $(this).val(splitVal[0]);
                    }
                }
            }
        });
    }

    /**
     * Initialize select boxes
     *
     * @param {jQuery} $container
     */
    static initializeSelects($container) {
        $container.find('[data-plugin-select2], [data-plugin-select-two]').each(function() {
            let $select        = $(this);
            let defaultOptions = {
                theme: 'bootstrap4',
                width: '100%'
            };
            let options        = $select.data('plugin-options') || {};
            options            = $.extend(true, defaultOptions, options);

            $select.select2(options);
        });
    }

    /**
     * Initialize summernote text editors
     *
     * @param {jQuery} $container
     */
    static initializeSummernote($container) {
        $container.find('[data-plugin-summernote]').each(function() {
            let $textarea      = $(this);
            let defaultOptions = {};
            let options        = $textarea.data('plugin-options') || {};
            options            = $.extend(true, defaultOptions, options);

            $textarea.summernote(options);
        });
    }

    /**
     * Initialize navbar
     *
     */
    static initializeNavbar() {
        $(document).ready(function() {
            // Mobile menu toggle
            $('.menu-toggle').click(function(e) {
                e.preventDefault();
                let $sidebar     = $('.content-sidebar');
                let isMenuActive = $sidebar.hasClass('active');
                $sidebar.toggleClass('active', !isMenuActive);
                $(this).toggleClass('active', !isMenuActive);
                $('body').toggleClass('menu-active', !isMenuActive);
            });

            // Toggle sidebar groups
            $('.sidebar-nav-dropdown-toggle').click(function(e) {
                e.preventDefault();
                $(this).parent().toggleClass('show');
            });
        });
    }

    /**
     * Initialize Slugify on fields
     *
     * @param {jQuery} $container
     */
    static initializeSlugify($container) {
        $container.on('keyup', '[data-slugify]', function() {
            let $source = $(this);
            let $target = $($source.data('slugify'));
            if ($target.length) {
                let slug = slugify($source.val(), {
                    lower:  true,
                    strict: true,
                });
                $target.val(slug).trigger('change');
            }
        });
    }

    /**
     * Initialize Sortable on overviews
     *
     * @param {jQuery} $container
     */
    static initializeSortable($container) {
        $container.find('[data-sortable]').each(function() {
            let element      = this;
            let $element     = $(this);
            let options      = $(this).data('sortable');
            let $loader      = $element.closest('[data-loader]')
            let hasLoader    = $loader.length;
            let groupName    = options.groupName;
            let saveUrl      = options.saveUrl;
            let configErrors = [];

            if (typeof groupName === 'undefined') {
                configErrors.push('groupName');
            }
            if (typeof saveUrl === 'undefined') {
                configErrors.push('saveUrl');
            }
            if (configErrors.length) {
                console.error('Sortable: check if this/these required option(s) are set in the config: ' + configErrors.join(', '));
                return;
            }
            let defaults = {
                scrollSensitivity: 100,
                forceFallback:     true,
                scroll:            true,
                handle:            ".sortable-handle",
                draggable:         ".sortable-draggable",
                errorElement:      '.sortable-error',
                errorMsg:          'Error while saving positions, please try again',
                group:             groupName,
                store:             {
                    /**
                     * Prevent reordering the current list, return empty array
                     * @param   {Sortable}  sortable
                     * @returns {Array}
                     */
                    get: (sortable) => {
                        return [];
                    },

                    /**
                     * Save the order of elements when element is dropped
                     * @param {Sortable}  sortable
                     */
                    set: (sortable) => {
                        hasLoader && $loader.addClass('loading');
                        let order = sortable.toArray();
                        let data = {
                            positions: order
                        };
                        if (options.page && options.pageSize !== -1) {
                            data.page = options.page;
                            data.pageSize = options.pageSize || 20;
                        }
                        $.ajax({
                            url:  saveUrl,
                            data: data,
                            type: 'POST',
                        }).done(response => {
                            // Update position numbers after save
                            if (typeof response.updatedPositions !== 'undefined') {
                                response.updatedPositions.forEach(function(productGroup) {
                                    $element.find(`[data-id="${productGroup.id}"] [data-position]`).html(productGroup.position);
                                });
                            }
                        }).fail((xhr) => {
                            if (xhr.status === 0 && xhr.statusText === 'abort') {
                                // Call aborted, don't do anything
                                return;
                            }
                            if (typeof options.errorElement !== 'undefined' && typeof options.errorMsg !== 'undefined') {
                                let $errorElement = $(options.errorElement);
                                $errorElement.html(options.errorMsg).addClass('active');
                                setTimeout(function() {
                                    $errorElement.removeClass('active');
                                }, 3000)
                            }
                        }).always(function() {
                            hasLoader && $loader.removeClass('loading');
                        });
                    }
                },
            };

            options = $.extend(defaults, options);

            Sortable.create(element, options);
        });
    }
}
