<template>
    <div>
        <div v-if="settings.multiple" class="row">
            <input type="hidden" :name="field" v-if="items.length === 0"/>
            <div v-for="(item, itemIndex) in items" class="col-sm-12">
                <input type="hidden" :name="field + '[' + itemIndex + '][id]'" v-model="item.id">
                <input type="hidden" :name="field + '[' + itemIndex + '][locale]'" :value="settings.locale" v-if="settings.locale">
                <div class="row mb-2">
                    <div class="col-md-2">
                        <img :src="settings.respondUrl + item.hash_id"
                             :alt="item.name"
                             class="image-preview"
                             v-if="item.content_type.indexOf('image') === 0"/>
                        <span v-else class="file-type">{{ item.extension }}</span>
                    </div>
                    <div class="col-md-7">
                        <strong>{{ item.name }}</strong>
                    </div>
                    <div class="col-md-3 text-right">
                        <a href="javascript:;" class="btn btn-outline-danger btn-xs" v-on:click="items.splice(itemIndex, 1)">{{ settings.textRemove }}</a>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="items !== null && items !== undefined" class="row mb-2">
            <input type="hidden" :name="field + '[id]'" v-model="items.id">
            <input type="hidden" :name="field + '[locale]'" :value="settings.locale" v-if="settings.locale">
            <div class="col-md-2">
                <img :src="settings.respondUrl + items.hash_id"
                     :alt="items.name"
                     class="image-preview"
                     v-if="items.content_type.indexOf('image') === 0"/>
                <span v-else class="file-type">{{ items.extension }}</span>
            </div>
            <div class="col-md-7">
                <strong>{{ items.name }}</strong>
            </div>
            <div class="col-md-3 text-right">
                <a href="javascript:;" class="btn btn-outline-danger btn-xs" v-on:click="items = null">{{ settings.textRemove }}</a>
            </div>
        </div>
        <input v-else type="hidden" :name="field" value="">
        <div class="row">
            <div class="col-sm-12">
                <vue-dropzone
                    ref="assetDropzone"
                    :id="'dropzone-' + widgetId"
                    :options="settings.dropzone">
                </vue-dropzone>
            </div>
        </div>
    </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone/dist/vue2Dropzone';

export default {
    name:       'assets',
    props:      ['field', 'assets', 'settings'],
    components: {
        'vue-dropzone': vue2Dropzone,
    },
    data() {
        return {
            widgetId: null,
            // Do not mutate `assets` prop directly to avoid Vue warnings
            items: this.assets
        }
    },
    mounted() {
        this.widgetId = this._uid;
        this.$refs.assetDropzone.$on('vdropzone-error', this.uploadFailed);
        this.$refs.assetDropzone.$on('vdropzone-success', this.uploadSuccess);
    },
    methods: {
        uploadSuccess: function(file, response, event) {
            // Remove the file from the list, we render items ourselves
            this.$refs.assetDropzone.removeFile(file);

            // Append or set the item depending on collection or not
            if (this.settings.multiple) {
                this.items.push(response.asset);
            } else {
                this.items = response.asset;
            }
        },
        uploadFailed: function(file, message, xhr) {
            this.$refs.assetDropzone.removeFile(file);
        },
    }
}
</script>
