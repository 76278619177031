<template>
    <div :class="'col-auto' + (repeatable ? ' ml-5' : '')">
        <b-form inline>
            <b-button v-if="repeatable" variant="danger" class="btn-sm mr-2 delete-row icon-only" @click="$emit('delete-button-clicked')"><i class="fa fa-minus-circle"></i></b-button>
            <label class="mr-sm-2" for="logic">If</label>
            <b-form-select
                id="logic"
                class="mb-2 mr-sm-2 mb-sm-0"
                :options="[{value: 'AND', text: 'All'}, {value: 'OR', text: 'Any'}]"
                v-model="logic"
            ></b-form-select>
            <label class="mr-sm-2" for="negate">of the conditions are true</label>
            <!--            <b-form-select-->
            <!--                id="negate"-->
            <!--                class="mb-2 mr-sm-2 mb-sm-0"-->
            <!--                :options="[{value: 1, text: 'True'}, {value: 0, text: 'False'}]"-->
            <!--                v-model="negate"-->
            <!--            ></b-form-select>-->
        </b-form>
        <b-form>
            <b-button v-if="repeatable" variant="primary" class="btn-sm icon-only ml-3" @click="addChild()"><i class="fa fa-plus-circle"></i></b-button>
        </b-form>
    </div>
</template>

<script>
import Condition from "./Condition";

export default {
    name:       'combination',
    components: {
        Condition,
    },
    data() {
        return {
          logic:  'AND',
          negate: 0,
        }
    },
    computed: {
        // Because reactivity is limited with objects, we do this way to sync data with parent
        combination: function() {
            return {
                'logic':  this.logic,
                'negate': this.negate,
            }
        }
    },
    props:    {
        id:         {
            required: true
        },
        repeatable: {
            type:    Boolean,
            default: false
        },
        value:      {
            type: Object,
            default() {
                return {}
            }
        }
    },
    created() {
        if (this.value && this.value.logic) {
            this.logic = this.value.logic;
        }
        if (this.value && this.value.negate) {
            this.negate = this.value.negate;
        }
        this.$emit('data-updated', this.id, this.combination);
    },
    methods: {
        addChild() {
            this.$emit('combination-child-added', this.id);
        }
    },
    watch: {
        logic: function(newVal, oldVal) {
            this.$emit('data-updated', this.id, this.combination);
        },
        // negate: function(newVal, oldVal) {
        //     this.$emit('data-updated', this.id, this.combination);
        // },
    }
}
</script>
