<template>
  <div class="col-auto">
    <b-form-group>
      <b-form inline>
        <b-button v-if="showDelete" variant="danger" class="btn-sm delete-row icon-only mr-2" @click="$emit('delete-button-clicked')"><i class="fa fa-minus-circle"></i></b-button>
        <b-form-select
            id="consequence-type"
            class="mb-2 mr-sm-2 mb-sm-0"
            v-model="type"
        >
          <b-form-select-option :value="null">--- Please select an option ---</b-form-select-option>
          <b-form-select-option v-for="(consequenceTypeText, consequenceTypeKey) in consequenceTypes" :key="consequenceTypeKey" :value="consequenceTypeKey">{{ consequenceTypeText }}</b-form-select-option>
        </b-form-select>
        <v-select v-if="options.length > 0" id="options" label="text" multiple :reduce="(option) => option.value" :options="options" v-model="entity"></v-select>
      </b-form>
    </b-form-group>
  </div>
</template>

<script>
import vSelect from 'vue-select';

export default {
  name:       'consequence',
  components: {
    'v-select': vSelect
  },
  data() {
    return {
      options: [],
      entity:  null,
      type:    null,
    }
  },
  props:    {
    id:               {
      required: true
    },
    parentId:         {
      required: false
    },
    showDelete:       {
      type:    Boolean,
      default: true
    },
    consequenceTypes: {
      type: Object,
      default() {
        return {}
      }
    },
    value:            {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    // Because reactivity is limited with objects, we do this way to sync data with parent
    consequence: function() {
      return {
        'id':     this.id,
        'entity': this.entity,
        'type':   this.type,
      }
    },
  },
  created() {
    Object.keys(this.value).forEach(key => {
      if (key !== 'id' && this.value[key] !== undefined && this[key] !== undefined) {
        this[key] = this.value[key];
      }
    });
  },
  methods: {
    emitConsequence() {
      this.$emit('data-updated', this.id, this.consequence, this.parentId);
    }
  },
  watch:   {
    type: function(val, oldVal) {
      const conditions = [
        {type: 'AddPromotionMutator', model: 'Promotions'},
        {type: 'AddPromotionGroupMutator', model: 'PromotionGroups'},
        {type: ['AddAddonGroupMutator', 'AddonGroupCanNotBeCombinedMutator'], model: 'AddonGroups'},
        {type: 'AddIncludedAddonMutator', model: 'Addons'},
        {type: ['AddAvailableRegionMutator', 'AddUnavailableRegionMutator'], model: 'Regions'},
        {type: ['AddAvailableProvinceMutator', 'AddUnavailableProvinceMutator'], model: 'Provinces'},
        {type: ['AddAvailableMainMunicipalityMutator', 'AddUnavailableMainMunicipalityMutator'], model: 'MainMunicipalities'},
        {type: ['AddAvailableSubMunicipalityMutator', 'AddUnavailableSubMunicipalityMutator'], model: 'SubMunicipalities'}
      ];
      const match = conditions.find(c => {
        if (Array.isArray(c.type)) {
          return c.type.includes(val);
        } else {
          return c.type === val;
        }
      });
      const model = match ? match.model : 'Products';

      if (oldVal) {
        this.entity = [];
      }

      this.$emit('data-updated', this.id, this.consequence, this.parentId);
      this.options = [];
      if (val === 'combination') {
        this.$emit('add-button-clicked', 'combination', this.id)
      } else if (val !== undefined && this.type) {
        $.ajax({
          url:  '/conditionals/type-options',
          data: {'model': model},
          type: 'POST',
        }).done(response => {
          this.options = response.data;
        }).fail((xhr) => {
          this.$bvToast.toast('Server Error', {
            title:         'Consequence options could not be updated.',
            variant:       'danger',
            autoHideDelay: 5000,
            appendToast:   false,
          })
        });
      }
    },
    entity: function() {
      this.emitConsequence();
    },
  }
}
</script>
