<template>
  <div :class="[{'is-invalid': Object.keys(errors).length > 0}]">
    <div class="row mb-1" v-if="pairs.length">
      <div :class="translating ? 'col-md-6': 'col-md-5'">
        Key
      </div>
      <div :class="translating ? 'col-md-6' : 'col-md-5'">
        Value
      </div>
    </div>
    <div class="form-group row justify-content-end" v-for="(pair, index) in pairs">
      <div v-if="translating" class="col-md-6">
        <input type="hidden" class="form-control" :name="inputName(index, 'key')" v-model="pair.key">
        <input type="text" disabled class="form-control" v-model="pair.key">
      </div>
      <div v-else class="col-md-5">
        <input type="text" class="form-control" :name="inputName(index, 'key')" v-model="pair.key">
      </div>
      <div :class="translating ? 'col-md-6' : 'col-md-5'">
        <input type="text" class="form-control" :name="inputName(index, 'value')" v-model="pair.value">
      </div>
      <div class="col-md-2" v-if="!translating">
        <i class="fas fa-trash" v-if="pairs.length > 1" @click="deletePair(index)"></i>
      </div>
    </div>
    <button v-if="!translating" @click="addNewPair()" type="button" class="btn btn-primary btn-sm float-right"><i class="fas fa-plus"></i></button>
  </div>
</template>

<script>
export default {
  name: "key-value-multiselect",
  data() {
    return {
      pairs: [],
    }
  },
  props: {
    value:  {
      type:    Array,
      default: [],
    },
    translating: {
      type:    Boolean,
      default: false
    },
    locale: {
      type:     String,
      required: true
    },
    errors: {
      type: Object | Array,
      default: () => ({})
    },
  },
  created() {
    this.pairs = this.value;
  },
  mounted() {
    if (this.pairs.length === 0) {
      this.addNewPair();
    }
  },
  methods: {
    addNewPair() {
      this.pairs.push({key: null, value: null});
    },
    deletePair(index) {
      this.$delete(this.pairs, index);
    },
    inputName(index, key) {
      if (this.translating) {
        return '_translations[' + this.locale + '][info][select_options][' + index + '][' + key + ']';
      }
      return 'info[select_options][' + index + '][' + key + ']';
    }
  }
};
</script>
