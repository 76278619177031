var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.resultCount > _vm.itemsPerPage
    ? _c("tr", [
        _c("td", [
          _c(
            "ul",
            {
              staticClass: "pagination b-pagination",
              attrs: { role: "menubar" },
            },
            [
              _c(
                "li",
                {
                  class: { disabled: _vm.isFirstPage, "page-item": true },
                  attrs: { role: "presentation", "aria-hidden": "true" },
                },
                [
                  _vm.isFirstPage
                    ? _c(
                        "span",
                        {
                          staticClass: "page-link disabled",
                          attrs: {
                            role: "menuitem",
                            "aria-controls": "pagination-table",
                            "aria-disabled": "true",
                          },
                        },
                        [_vm._v("«")]
                      )
                    : _c(
                        "button",
                        {
                          staticClass: "page-link",
                          attrs: { role: "menuitem", type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.setPage(1)
                            },
                          },
                        },
                        [_vm._v("«")]
                      ),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.totalPages, function (pageNumber) {
                return Math.abs(pageNumber - _vm.currentPage) <
                  3 + _vm.itemsPerPage ||
                  pageNumber === _vm.totalPages ||
                  pageNumber === 1
                  ? _c(
                      "li",
                      {
                        class: _vm.getPaginationItemClass(pageNumber),
                        attrs: { role: "presentation" },
                      },
                      [
                        _c(
                          "button",
                          {
                            key: pageNumber,
                            staticClass: "page-link",
                            attrs: { role: "menuitemradio", type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.setPage(pageNumber)
                              },
                            },
                          },
                          [_vm._v(_vm._s(pageNumber))]
                        ),
                      ]
                    )
                  : _vm._e()
              }),
              _vm._v(" "),
              _c(
                "li",
                {
                  class: { disabled: _vm.isLastPage, "page-item": true },
                  attrs: { role: "presentation" },
                },
                [
                  _vm.isLastPage
                    ? _c(
                        "span",
                        {
                          staticClass: "page-link disabled",
                          attrs: {
                            role: "menuitem",
                            "aria-controls": "pagination-table",
                            "aria-disabled": "true",
                          },
                        },
                        [_vm._v("»")]
                      )
                    : _c(
                        "button",
                        {
                          staticClass: "page-link",
                          attrs: { role: "menuitem", type: "button" },
                          on: {
                            click: function ($event) {
                              return _vm.setPage(_vm.totalPages)
                            },
                          },
                        },
                        [_vm._v("»")]
                      ),
                ]
              ),
            ],
            2
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }