var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.containerClass },
    [
      _c("input", { attrs: { type: "hidden", name: _vm.name } }),
      _vm._v(" "),
      _vm._l(_vm.destination, function (option, i) {
        return [
          _c("input", {
            attrs: {
              type: "hidden",
              name:
                _vm.name +
                (_vm.primaryKey ? "[" + i + "][" + _vm.primaryKey + "]" : "[]"),
            },
            domProps: { value: option.code },
          }),
        ]
      }),
      _vm._v(" "),
      _c("DualListBox", {
        attrs: {
          label: "name",
          source: _vm.source,
          destination: _vm.destination,
        },
        on: { onChangeList: _vm.onChangeList },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }