var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "composer-container" },
    [
      _vm.success
        ? _c(
            "div",
            { staticClass: "alert alert-success", attrs: { role: "alert" } },
            [
              _vm._v("\n    Conditional has been added successfully."),
              _c("br"),
              _vm._v(" "),
              _c("a", { attrs: { href: "/conditionals" } }, [
                _vm._v("Go Back"),
              ]),
            ]
          )
        : [
            _c("h2", [_vm._v("Conditionals")]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c(
                  "b-form",
                  [
                    _c(
                      "label",
                      { staticClass: "mr-sm-2", attrs: { for: "title" } },
                      [_vm._v("Title")]
                    ),
                    _vm._v(" "),
                    _c("b-form-input", {
                      staticClass: "mb-2 mr-sm-2 mb-sm-0",
                      attrs: { id: "title" },
                      model: {
                        value: _vm.title,
                        callback: function ($$v) {
                          _vm.title = $$v
                        },
                        expression: "title",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("h2", [_vm._v("Conditions")]),
            _vm._v(" "),
            _c("combination", {
              attrs: { combinationData: {}, id: "parent" },
              on: { "data-updated": _vm.updateTopConditionData },
              model: {
                value: _vm.topCondition,
                callback: function ($$v) {
                  _vm.topCondition = $$v
                },
                expression: "topCondition",
              },
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _vm._l(_vm.conditions, function (condition, index) {
              return _c(
                "div",
                { key: condition.key },
                [
                  condition.type === "condition"
                    ? _c("condition", {
                        attrs: {
                          id: condition.data.hasOwnProperty("id")
                            ? condition.data.id
                            : index,
                          "allow-children": true,
                        },
                        on: {
                          "data-updated": _vm.updateConditionData,
                          "add-button-clicked": _vm.updateConditionType,
                          "delete-button-clicked": function ($event) {
                            return _vm.deleteCondition(index)
                          },
                        },
                        model: {
                          value: condition.data,
                          callback: function ($$v) {
                            _vm.$set(condition, "data", $$v)
                          },
                          expression: "condition.data",
                        },
                      })
                    : condition.type === "combination"
                    ? _c("combination", {
                        attrs: {
                          id: condition.data.hasOwnProperty("id")
                            ? condition.data.id
                            : index,
                          repeatable: true,
                        },
                        on: {
                          "data-updated": _vm.updateConditionData,
                          "combination-child-added": function ($event) {
                            return _vm.addCondition("combination", index)
                          },
                          "delete-button-clicked": function ($event) {
                            return _vm.deleteCondition(index)
                          },
                        },
                        model: {
                          value: condition.data,
                          callback: function ($$v) {
                            _vm.$set(condition, "data", $$v)
                          },
                          expression: "condition.data",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(condition.children, function (childCondition, i) {
                    return _c(
                      "div",
                      {
                        key: "child_" + childCondition.key,
                        staticClass: "ml-5",
                      },
                      [
                        _c("condition", {
                          attrs: {
                            id: childCondition.data.hasOwnProperty("id")
                              ? childCondition.data.id
                              : i,
                            "parent-id": index,
                          },
                          on: {
                            "delete-button-clicked": function ($event) {
                              return _vm.deleteCondition(index, i)
                            },
                            "data-updated": _vm.updateConditionData,
                          },
                          model: {
                            value: childCondition.data,
                            callback: function ($$v) {
                              _vm.$set(childCondition, "data", $$v)
                            },
                            expression: "childCondition.data",
                          },
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              )
            }),
            _vm._v(" "),
            _c("div", { staticClass: "row mb-4 mr-2" }, [
              _c(
                "div",
                { staticClass: "col-md-auto" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-lg",
                      attrs: { variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.addCondition("condition")
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-plus-circle" })]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("h2", [_vm._v("Consequences")]),
            _vm._v(" "),
            _vm._l(_vm.consequences, function (consequence, index) {
              return _c(
                "div",
                { key: consequence.type },
                [
                  _c("consequence", {
                    attrs: {
                      id: index,
                      "consequence-types": _vm.consequenceTypes,
                      "allow-children": false,
                    },
                    on: {
                      "data-updated": _vm.updateConsequenceData,
                      "delete-button-clicked": function ($event) {
                        return _vm.deleteConsequence(index)
                      },
                    },
                    model: {
                      value: consequence.data,
                      callback: function ($$v) {
                        _vm.$set(consequence, "data", $$v)
                      },
                      expression: "consequence.data",
                    },
                  }),
                ],
                1
              )
            }),
            _vm._v(" "),
            _c("div", { staticClass: "row mb-4 mr-2" }, [
              _c(
                "div",
                { staticClass: "col-md-auto" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "btn-lg",
                      attrs: { variant: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.addConsequence()
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-plus-circle" })]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "section",
              { staticClass: "composer-section spacer consequences" },
              [
                _c(
                  "b-button",
                  {
                    attrs: {
                      variant: "primary",
                      type: "submit",
                      disabled: _vm.loading,
                    },
                    on: { click: _vm.sendForm },
                  },
                  [_vm._v("Save")]
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }