var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("input", { attrs: { type: "hidden", name: _vm.field } }),
      _vm._v(" "),
      _vm._l(
        _vm.chosenSpecifications,
        function (chosenSpecification, fieldIndex) {
          return _c("div", { staticClass: "form-group row" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: chosenSpecification.id,
                  expression: "chosenSpecification.id",
                },
              ],
              attrs: {
                type: "hidden",
                name: _vm.field + "[" + fieldIndex + "][id]",
              },
              domProps: { value: chosenSpecification.id },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(chosenSpecification, "id", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _c("div", { class: _vm.translating ? "col-md-2" : "col-md-3" }, [
              _vm.specifications[chosenSpecification.id].url
                ? _c("b", [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.specifications[chosenSpecification.id].url,
                          target: "_blank",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.specifications[chosenSpecification.id].name
                          )
                        ),
                      ]
                    ),
                  ])
                : _c("b", [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.specifications[chosenSpecification.id].name
                        ) +
                        "\n      "
                    ),
                  ]),
            ]),
            _vm._v(" "),
            _vm.translating
              ? _c("div", { staticClass: "col-md-5" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        chosenSpecification._joinData.default_locale_value
                      ) +
                      "\n    "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { class: _vm.translating ? "col-md-5" : "col-md-9" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { class: [{ "col-md-10": !_vm.translating }, "col"] },
                  [
                    _vm.specifications[chosenSpecification.id].type === "date"
                      ? _c("div", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: chosenSpecification._joinData.data,
                                expression:
                                  "chosenSpecification._joinData.data",
                              },
                            ],
                            attrs: {
                              type: "date",
                              name: _vm.getFieldName(fieldIndex),
                            },
                            domProps: {
                              value: chosenSpecification._joinData.data,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  chosenSpecification._joinData,
                                  "data",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ])
                      : _vm.specifications[chosenSpecification.id].type ===
                        "time"
                      ? _c("div", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: chosenSpecification._joinData.data,
                                expression:
                                  "chosenSpecification._joinData.data",
                              },
                            ],
                            attrs: {
                              type: "time",
                              name: _vm.getFieldName(fieldIndex),
                            },
                            domProps: {
                              value: chosenSpecification._joinData.data,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  chosenSpecification._joinData,
                                  "data",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ])
                      : _vm.specifications[chosenSpecification.id].type ===
                        "datetime"
                      ? _c(
                          "div",
                          [
                            _c("datetime", {
                              attrs: {
                                type: "datetime",
                                name: _vm.getFieldName(fieldIndex),
                              },
                              model: {
                                value: chosenSpecification._joinData.data,
                                callback: function ($$v) {
                                  _vm.$set(
                                    chosenSpecification._joinData,
                                    "data",
                                    $$v
                                  )
                                },
                                expression:
                                  "chosenSpecification._joinData.data",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm.specifications[chosenSpecification.id].type ===
                        "string"
                      ? _c("div", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: chosenSpecification._joinData.data,
                                expression:
                                  "chosenSpecification._joinData.data",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: _vm.getFieldName(fieldIndex),
                            },
                            domProps: {
                              value: chosenSpecification._joinData.data,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  chosenSpecification._joinData,
                                  "data",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ])
                      : _vm.specifications[chosenSpecification.id].type ===
                        "text"
                      ? _c("div", [
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: chosenSpecification._joinData.data,
                                expression:
                                  "chosenSpecification._joinData.data",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { name: _vm.getFieldName(fieldIndex) },
                            domProps: {
                              value: chosenSpecification._joinData.data,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  chosenSpecification._joinData,
                                  "data",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ])
                      : _vm.specifications[chosenSpecification.id].type ===
                        "boolean"
                      ? _c("div", [
                          _c("input", {
                            attrs: {
                              type: "hidden",
                              value: "false",
                              name: _vm.getFieldName(fieldIndex),
                            },
                          }),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: chosenSpecification._joinData.data,
                                expression:
                                  "chosenSpecification._joinData.data",
                              },
                            ],
                            attrs: {
                              type: "checkbox",
                              name: _vm.getFieldName(fieldIndex),
                              value: "true",
                            },
                            domProps: {
                              checked: Array.isArray(
                                chosenSpecification._joinData.data
                              )
                                ? _vm._i(
                                    chosenSpecification._joinData.data,
                                    "true"
                                  ) > -1
                                : chosenSpecification._joinData.data,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = chosenSpecification._joinData.data,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = "true",
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        chosenSpecification._joinData,
                                        "data",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        chosenSpecification._joinData,
                                        "data",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    chosenSpecification._joinData,
                                    "data",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                        ])
                      : _vm.specifications[chosenSpecification.id].type ===
                        "integer"
                      ? _c(
                          "div",
                          [
                            (_vm.unlimited[fieldIndex]
                              ? "hidden"
                              : "number") === "checkbox"
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: chosenSpecification._joinData.data,
                                      expression:
                                        "chosenSpecification._joinData.data",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    name: _vm.getFieldName(fieldIndex),
                                    step: "1",
                                    type: "checkbox",
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      chosenSpecification._joinData.data
                                    )
                                      ? _vm._i(
                                          chosenSpecification._joinData.data,
                                          null
                                        ) > -1
                                      : chosenSpecification._joinData.data,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a =
                                          chosenSpecification._joinData.data,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            _vm.$set(
                                              chosenSpecification._joinData,
                                              "data",
                                              $$a.concat([$$v])
                                            )
                                        } else {
                                          $$i > -1 &&
                                            _vm.$set(
                                              chosenSpecification._joinData,
                                              "data",
                                              $$a
                                                .slice(0, $$i)
                                                .concat($$a.slice($$i + 1))
                                            )
                                        }
                                      } else {
                                        _vm.$set(
                                          chosenSpecification._joinData,
                                          "data",
                                          $$c
                                        )
                                      }
                                    },
                                  },
                                })
                              : (_vm.unlimited[fieldIndex]
                                  ? "hidden"
                                  : "number") === "radio"
                              ? _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: chosenSpecification._joinData.data,
                                      expression:
                                        "chosenSpecification._joinData.data",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    name: _vm.getFieldName(fieldIndex),
                                    step: "1",
                                    type: "radio",
                                  },
                                  domProps: {
                                    checked: _vm._q(
                                      chosenSpecification._joinData.data,
                                      null
                                    ),
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.$set(
                                        chosenSpecification._joinData,
                                        "data",
                                        null
                                      )
                                    },
                                  },
                                })
                              : _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: chosenSpecification._joinData.data,
                                      expression:
                                        "chosenSpecification._joinData.data",
                                    },
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    name: _vm.getFieldName(fieldIndex),
                                    step: "1",
                                    type: _vm.unlimited[fieldIndex]
                                      ? "hidden"
                                      : "number",
                                  },
                                  domProps: {
                                    value: chosenSpecification._joinData.data,
                                  },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        chosenSpecification._joinData,
                                        "data",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                            _vm._v(" "),
                            _c(
                              "b-form-checkbox",
                              {
                                attrs: {
                                  id: "unlimited_" + fieldIndex,
                                  "unchecked-value": false,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.unlimitedClicked(fieldIndex)
                                  },
                                },
                                model: {
                                  value: _vm.unlimited[fieldIndex],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.unlimited, fieldIndex, $$v)
                                  },
                                  expression: "unlimited[fieldIndex]",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              Unlimited\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm.specifications[chosenSpecification.id].type ===
                        "double"
                      ? _c("div", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: chosenSpecification._joinData.data,
                                expression:
                                  "chosenSpecification._joinData.data",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "number",
                              name: _vm.getFieldName(fieldIndex),
                              step: "0.1",
                            },
                            domProps: {
                              value: chosenSpecification._joinData.data,
                            },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  chosenSpecification._joinData,
                                  "data",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ])
                      : _vm.specifications[chosenSpecification.id].type ===
                        "html"
                      ? _c(
                          "div",
                          [
                            _c("summernote", {
                              attrs: {
                                name: _vm.getFieldName(fieldIndex),
                                model: chosenSpecification._joinData.data,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm.specifications[chosenSpecification.id].type ===
                        "select"
                      ? _c("div", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: chosenSpecification._joinData.data,
                                  expression:
                                    "chosenSpecification._joinData.data",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: { name: _vm.getFieldName(fieldIndex) },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    chosenSpecification._joinData,
                                    "data",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            _vm._l(
                              _vm.specifications[chosenSpecification.id].info
                                .select_options,
                              function (option, i) {
                                return _c(
                                  "option",
                                  { domProps: { value: option } },
                                  [_vm._v(_vm._s(option))]
                                )
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm.specifications[chosenSpecification.id].type ===
                        "multiselect"
                      ? _c("div", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: chosenSpecification._joinData.data,
                                  expression:
                                    "chosenSpecification._joinData.data",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                name: _vm.getFieldName(fieldIndex, true),
                                options:
                                  _vm.specifications[chosenSpecification.id]
                                    .info.select_options,
                                multiple: true,
                              },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    chosenSpecification._joinData,
                                    "data",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            _vm._l(
                              _vm.specifications[chosenSpecification.id].info
                                .select_options,
                              function (option, i) {
                                return _c(
                                  "option",
                                  { domProps: { value: option } },
                                  [_vm._v(_vm._s(option))]
                                )
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm.specifications[chosenSpecification.id].type ===
                        "products"
                      ? _c("div", [
                          _c("select", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: chosenSpecification._joinData.data,
                                expression:
                                  "chosenSpecification._joinData.data",
                              },
                            ],
                            attrs: {
                              name: _vm.getFieldName(fieldIndex, true),
                              label: "name",
                              options:
                                _vm.specifications[chosenSpecification.id].info
                                  .select_options,
                              multiple: true,
                            },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  chosenSpecification._joinData,
                                  "data",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          }),
                        ])
                      : _vm.specifications[chosenSpecification.id].type ===
                        "key-value-pairs"
                      ? _c(
                          "div",
                          [
                            _vm._l(
                              _vm.specifications[chosenSpecification.id].info
                                .select_options,
                              function (selectOption) {
                                return [
                                  _c("label", [_vm._v(_vm._s(selectOption))]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          chosenSpecification._joinData.data[
                                            selectOption
                                          ],
                                        expression:
                                          "chosenSpecification._joinData.data[selectOption]",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "text",
                                      name: _vm.getFieldName(
                                        fieldIndex,
                                        selectOption
                                      ),
                                    },
                                    domProps: {
                                      value:
                                        chosenSpecification._joinData.data[
                                          selectOption
                                        ],
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          chosenSpecification._joinData.data,
                                          selectOption,
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              }
                            ),
                          ],
                          2
                        )
                      : _vm.specifications[chosenSpecification.id].type ===
                        "key-value-pairs-multiselect"
                      ? _c("div", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: chosenSpecification._joinData.data,
                                  expression:
                                    "chosenSpecification._joinData.data",
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                name: _vm.getFieldName(fieldIndex, true),
                                options:
                                  _vm.specifications[chosenSpecification.id]
                                    .info.select_options,
                                multiple: true,
                              },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    chosenSpecification._joinData,
                                    "data",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            _vm._l(
                              _vm.specifications[chosenSpecification.id].info
                                .select_options,
                              function (selectOption) {
                                return _c(
                                  "option",
                                  { domProps: { value: selectOption["key"] } },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(selectOption["key"]) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                !_vm.translating
                  ? _c("div", { staticClass: "col-md-2" }, [
                      _vm.isDeletable(
                        _vm.specifications[chosenSpecification.id]
                          .product_categories
                      )
                        ? _c("i", {
                            staticClass: "fa fa-minus-circle text-danger",
                            on: {
                              click: function ($event) {
                                return _vm.remove(chosenSpecification.id)
                              },
                            },
                          })
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ])
        }
      ),
      _vm._v(" "),
      !_vm.translating
        ? _c(
            "button",
            {
              staticClass: "btn-secondary btn btn-xs",
              attrs: { type: "button" },
              on: { click: _vm.showModal },
            },
            [_vm._v("\n    Add specification\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("modal", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isModalVisible,
            expression: "isModalVisible",
          },
        ],
        attrs: { width: "900px" },
        on: { close: _vm.closeModal },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v("\n      Specifications\n    ")]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("table", { staticClass: "table" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", { attrs: { scope: "col" } }, [_vm._v("Name")]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col" } }, [_vm._v("Key")]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col" } }, [_vm._v("Type")]),
                      _vm._v(" "),
                      _c("th", { attrs: { scope: "col" } }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    [
                      _vm._l(
                        _vm.paginatedSpecifications,
                        function (specification) {
                          return [
                            _c("tr", [
                              _c("th", { attrs: { scope: "row" } }, [
                                _vm._v(_vm._s(specification.name)),
                              ]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(specification.key))]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(specification.type))]),
                              _vm._v(" "),
                              _c("td", [
                                _c(
                                  "button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.showAddSpecButton(
                                          specification.id
                                        ),
                                        expression:
                                          "showAddSpecButton(specification.id)",
                                      },
                                    ],
                                    staticClass: "btn-primary btn btn-sm",
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.add(specification.id)
                                      },
                                    },
                                  },
                                  [_vm._v("Add")]
                                ),
                              ]),
                            ]),
                          ]
                        }
                      ),
                      _vm._v(" "),
                      _c("Pagination", {
                        attrs: {
                          "current-page": _vm.currentPage,
                          "items-per-page": _vm.itemsPerPage,
                          "result-count": _vm.resultCount,
                          "total-pages": _vm.totalPages,
                        },
                        on: {
                          "update:currentPage": function ($event) {
                            _vm.currentPage = $event
                          },
                          "update:current-page": function ($event) {
                            _vm.currentPage = $event
                          },
                        },
                      }),
                    ],
                    2
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
        ]),
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }