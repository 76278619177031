<template>
    <div>
        <div class="password-strength-label">{{ label }}</div>
        <div class="password-strength-bar" :class="passwordClass"></div>
    </div>
</template>

<script>
import {checkStrength, scorePassword} from './PasswordStrengthLogic';

export default {
    name:  'password-strength',
    props: {
        labelPassed: String,
        settings:    {
            type:     Object,
            required: true
        },
        password:    {
            type:     String,
            required: true
        },
    },
    data() {
        return {
            label: 'Password strength:',
        }
    },
    computed: {
        passwordClass() {
            if (!this.settings || !this.password) {
                return null;
            }
            if (typeof this.label !== 'undefined' && this.label.length === 0) {
                this.label = this.labelPassed;
            }
            const score    = scorePassword(this.password, this.settings);
            const strength = checkStrength(score);
            this.$emit('score', {score, strength});
            return {
                [strength]: true,
                scored:     true
            };
        }
    }
}
</script>

<style lang="scss" scoped>
.password-strength-label {
    font-size: .7em;
    text-transform: uppercase;
    margin-bottom: -6px;
}

.password-strength-bar {
    height: 5px;
    margin-top: 8px;
    border-radius: 2px;
    transition: all 0.2s linear;

    &.risky {
        width: 10%;
        background-color: #f95e68;
    }

    &.guessable {
        width: 32.5%;
        background-color: #fb964d;
    }

    &.weak {
        width: 55%;
        background-color: #fdd244;
    }

    &.safe {
        width: 77.5%;
        background-color: #b0dc53;
    }

    &.secure {
        width: 100%;
        background-color: #35cc62;
    }
}
</style>
