var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "composer-container" },
    [
      _vm.success
        ? _c(
            "div",
            { staticClass: "alert alert-success", attrs: { role: "alert" } },
            [
              _vm._v("\n    Conditional has been added successfully."),
              _c("br"),
              _vm._v(" "),
              _c(
                "b-button",
                {
                  staticClass: "btn-sm",
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.success = null
                    },
                  },
                },
                [_vm._v("Add another one")]
              ),
            ],
            1
          )
        : [
            _c("consequence", {
              attrs: {
                id: "0",
                "consequence-types": _vm.consequenceTypes,
                "show-delete": false,
                "allow-children": false,
              },
              on: { "data-updated": _vm.updateConsequenceData },
              model: {
                value: _vm.consequence.data,
                callback: function ($$v) {
                  _vm.$set(_vm.consequence, "data", $$v)
                },
                expression: "consequence.data",
              },
            }),
            _vm._v(" "),
            _c(
              "section",
              { staticClass: "composer-section spacer consequences" },
              [
                _c(
                  "b-button",
                  {
                    attrs: {
                      variant: "primary",
                      type: "submit",
                      disabled: _vm.loading,
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.sendForm.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Add")]
                ),
              ],
              1
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }