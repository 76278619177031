var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-auto" },
    [
      _c(
        "b-form-group",
        [
          _c(
            "b-form",
            { attrs: { inline: "" } },
            [
              _vm.showDelete
                ? _c(
                    "b-button",
                    {
                      staticClass: "btn-sm delete-row icon-only mr-2",
                      attrs: { variant: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("delete-button-clicked")
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-minus-circle" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-form-select",
                {
                  staticClass: "mb-2 mr-sm-2 mb-sm-0",
                  attrs: { id: "consequence-type" },
                  model: {
                    value: _vm.type,
                    callback: function ($$v) {
                      _vm.type = $$v
                    },
                    expression: "type",
                  },
                },
                [
                  _c("b-form-select-option", { attrs: { value: null } }, [
                    _vm._v("--- Please select an option ---"),
                  ]),
                  _vm._v(" "),
                  _vm._l(
                    _vm.consequenceTypes,
                    function (consequenceTypeText, consequenceTypeKey) {
                      return _c(
                        "b-form-select-option",
                        {
                          key: consequenceTypeKey,
                          attrs: { value: consequenceTypeKey },
                        },
                        [_vm._v(_vm._s(consequenceTypeText))]
                      )
                    }
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _vm.options.length > 0
                ? _c("v-select", {
                    attrs: {
                      id: "options",
                      label: "text",
                      multiple: "",
                      reduce: function (option) {
                        return option.value
                      },
                      options: _vm.options,
                    },
                    model: {
                      value: _vm.entity,
                      callback: function ($$v) {
                        _vm.entity = $$v
                      },
                      expression: "entity",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }