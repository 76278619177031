<template>
  <div :class="containerClass">
    <input type="hidden" :name="name"/>
    <template v-for="(option, i) in destination">
      <input type="hidden" :name="name + (primaryKey ? '[' + i + '][' + primaryKey + ']' : '[]')" :value="option.code">
    </template>
    <DualListBox label="name" @onChangeList="onChangeList" :source="source" :destination="destination"></DualListBox>
  </div>
</template>

<script>
import DualListBox from "dual-listbox-vue";

export default {
  name:       "dual-list",
  components: {
    DualListBox
  },
  props:      {
    containerClass: {
      type:     String,
      required: true,
    },
    name:           {
      type:     String,
      required: true
    },
    primaryKey:     {
      type:    String | false,
      default: 'id',
    },
    options:        {
      type:     Object,
      required: true
    },
    selected:       {
      type:    Array,
      default: () => []
    },
  },
  data() {
    return {
      source:      [],
      destination: [],
    }
  },
  mounted() {
    let selectedIds = this.selected;
    let primaryKey = this.primaryKey;
    if (primaryKey) {
      selectedIds = this.selected.map(option => {
        return option[primaryKey];
      })
    }
    selectedIds = selectedIds.map(id => {
      return !isNaN(id) ? Number(id) : id;
    });

    // Generate source and destination options
    Object.keys(this.options).forEach(key => {
      let option = {
        name: this.options[key],
        code: key,
      };
      if (!isNaN(key)) {
        key = Number(key);
      }
      if (selectedIds.includes(key)) {
        this.destination.push(option);
      } else {
        this.source.push(option);
      }
    });

    this.onChangeList({source: this.source, destination: this.destination});
  },
  methods: {
    onChangeList({source, destination}) {
      this.source = source;
      this.destination = destination;
    }
  }
};
</script>

<style lang="scss">
@import "../../style/general/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.dual-list {
  .list-box-wrapper {
    .btn-action {
      cursor: pointer;
      border-color: $primary;
      background-color: $primary;
    }

    .actions {
      padding: 0 10px;
    }

    .list-box-item {
      border-radius: 4px;

      .bulk-action {
        display: block;
        @include media-breakpoint-up(lg) {
          display: flex;
        }

        .select-all {
          cursor: pointer;
          font-size: 14px;
          text-transform: uppercase;
          padding: 10px;
          background-color: $primary;
        }

        .deselect-all {
          cursor: pointer;
          font-size: 14px;
          text-transform: uppercase;
          padding: 10px;
          background-color: $secondary;
        }
      }
    }
  }
}
</style>
