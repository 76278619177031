<template>
  <div class="composer-container">
    <div v-if="success" class="alert alert-success" role="alert">
      Conditional has been added successfully.<br/>
      <b-button variant="primary" class="btn-sm" @click.prevent="success = null">Add another one</b-button>
    </div>
    <template v-else>
      <consequence id="0" v-model="consequence.data" :consequence-types="consequenceTypes" :show-delete="false" @data-updated="updateConsequenceData" :allow-children="false"></consequence>
      <section class="composer-section spacer consequences">
        <b-button variant="primary" type="submit" @click.prevent="sendForm" :disabled="loading">Add</b-button>
      </section>
    </template>
  </div>
</template>

<script>
import Combination from "./Conditionals/Combination";
import Condition from "./Conditionals/Condition";
import Consequence from "./Conditionals/Consequence";

export default {
  name:       'add-conditional-widget',
  components: {
    Combination,
    Condition,
    Consequence,
  },
  props:      ['consequence', 'propositionId', 'consequenceTypes'],
  data() {
    return {
      form:                 {},
      loading:              false,
      success:              false,
      uniqueKeyConsequence: 0,
    }
  },
  methods: {
    updateConsequenceData(index, data) {
      this.consequence['data'] = data;
    },
    /**
     * Submit form pressed, validation steps here/send data through AJAX/follow-up steps
     */
    sendForm() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      $.ajax({
        url:  '/conditionals/widget', // TODO Replace with Cake route method and pass here
        data: {
          'consequence':    this.consequence,
          'proposition_id': this.propositionId
        },
        type: 'POST',
      }).done(response => {
        this.success = true;
      }).fail((xhr) => {
        if (xhr.status === 0 && xhr.statusText === 'abort') {
          // Call aborted, don't do anything
          return;
        }
        this.$bvToast.toast('Error', {
          title:         'Something went wrong.',
          variant:       'danger',
          autoHideDelay: 5000,
          appendToast:   false,
        })
      }).always(() => {
        this.loading = false;
      });
    },
  }
}
</script>
