<template>
  <div>
    <input type="hidden" :name="field"/>
    <div class="form-group row" v-for="(chosenSpecification, fieldIndex) in chosenSpecifications">
      <input type="hidden" :name="field + '[' + fieldIndex + '][id]'" v-model="chosenSpecification.id"/>
      <div :class="translating ? 'col-md-2' : 'col-md-3'">
        <b v-if="specifications[chosenSpecification.id].url">
          <a :href="specifications[chosenSpecification.id].url" target="_blank">{{ specifications[chosenSpecification.id].name }}</a>
        </b>
        <b v-else>
          {{ specifications[chosenSpecification.id].name }}
        </b>
      </div>
      <div v-if="translating" class="col-md-5">
        {{ chosenSpecification._joinData.default_locale_value }}
      </div>
      <div :class="translating ? 'col-md-5' : 'col-md-9'">
        <div class="row">
          <div :class="[{'col-md-10': !translating}, 'col']">
            <div v-if="specifications[chosenSpecification.id].type === 'date'">
              <input type="date" :name="getFieldName(fieldIndex)" v-model="chosenSpecification._joinData.data"/>
            </div>
            <div v-else-if="specifications[chosenSpecification.id].type === 'time'">
              <input type="time" :name="getFieldName(fieldIndex)" v-model="chosenSpecification._joinData.data"/>
            </div>
            <div v-else-if="specifications[chosenSpecification.id].type === 'datetime'">
              <datetime type="datetime" :name="getFieldName(fieldIndex)" v-model="chosenSpecification._joinData.data"></datetime>
            </div>
            <div v-else-if="specifications[chosenSpecification.id].type === 'string'">
              <input type="text" :name="getFieldName(fieldIndex)" v-model="chosenSpecification._joinData.data" class="form-control"/>
            </div>
            <div v-else-if="specifications[chosenSpecification.id].type === 'text'">
              <textarea :name="getFieldName(fieldIndex)" v-model="chosenSpecification._joinData.data" class="form-control"></textarea>
            </div>
            <div v-else-if="specifications[chosenSpecification.id].type === 'boolean'">
              <input type='hidden' value='false' :name="getFieldName(fieldIndex)"/>
              <input type="checkbox" :name="getFieldName(fieldIndex)" v-model="chosenSpecification._joinData.data" value="true"/>
            </div>
            <div v-else-if="specifications[chosenSpecification.id].type === 'integer'">
              <input :type="unlimited[fieldIndex] ? 'hidden' : 'number'" :name="getFieldName(fieldIndex)" step="1" v-model="chosenSpecification._joinData.data" class="form-control"/>
              <b-form-checkbox
                  :id="'unlimited_' + fieldIndex"
                  v-model="unlimited[fieldIndex]"
                  :unchecked-value="false"
                  @change="unlimitedClicked(fieldIndex)"
              >
                Unlimited
              </b-form-checkbox>
            </div>
            <div v-else-if="specifications[chosenSpecification.id].type === 'double'">
              <input type="number" :name="getFieldName(fieldIndex)" step="0.1" v-model="chosenSpecification._joinData.data" class="form-control"/>
            </div>
            <div v-else-if="specifications[chosenSpecification.id].type === 'html'">
              <summernote :name="getFieldName(fieldIndex)" :model="chosenSpecification._joinData.data"></summernote>
            </div>
            <div v-else-if="specifications[chosenSpecification.id].type === 'select'">
              <select class="form-control" :name="getFieldName(fieldIndex)" v-model="chosenSpecification._joinData.data">
                <option v-for="(option, i) in specifications[chosenSpecification.id].info.select_options" :value="option">{{ option }}</option>
              </select>
            </div>
            <div v-else-if="specifications[chosenSpecification.id].type === 'multiselect'">
              <select class="form-control" :name="getFieldName(fieldIndex, true)" v-model="chosenSpecification._joinData.data" :options="specifications[chosenSpecification.id].info.select_options" :multiple="true">
                <option v-for="(option, i) in specifications[chosenSpecification.id].info.select_options" :value="option">{{ option }}</option>
              </select>
            </div>
            <div v-else-if="specifications[chosenSpecification.id].type === 'products'">
              <select :name="getFieldName(fieldIndex, true)" v-model="chosenSpecification._joinData.data" label="name" :options="specifications[chosenSpecification.id].info.select_options" :multiple="true"></select>
            </div>
            <div v-else-if="specifications[chosenSpecification.id].type === 'key-value-pairs'">
              <template v-for="(selectOption) in specifications[chosenSpecification.id].info.select_options">
                <label>{{ selectOption }}</label>
                <input type="text" :name="getFieldName(fieldIndex, selectOption)" v-model="chosenSpecification._joinData.data[selectOption]" class="form-control"/>
              </template>
            </div>
            <div v-else-if="specifications[chosenSpecification.id].type === 'key-value-pairs-multiselect'">
              <select class="form-control"
                      :name="getFieldName(fieldIndex, true)"
                      v-model="chosenSpecification._joinData.data"
                      :options="specifications[chosenSpecification.id].info.select_options"
                      :multiple="true">
                <option v-for="(selectOption) in specifications[chosenSpecification.id].info.select_options" :value="selectOption['key']">
                  {{ selectOption['key'] }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-2" v-if="!translating">
            <i class="fa fa-minus-circle text-danger" v-if="isDeletable(specifications[chosenSpecification.id].product_categories)" @click="remove(chosenSpecification.id)"></i>
          </div>
        </div>
      </div>

    </div>
    <button type="button" class="btn-secondary btn btn-xs" @click="showModal" v-if="!translating">
      Add specification
    </button>
    <modal v-show="isModalVisible" width="900px" @close="closeModal">
      <template v-slot:header>
        Specifications
      </template>

      <template v-slot:body>
        <table class="table">
          <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Key</th>
            <th scope="col">Type</th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          <template v-for="specification in paginatedSpecifications">
            <tr>
              <th scope="row">{{ specification.name }}</th>
              <td>{{ specification.key }}</td>
              <td>{{ specification.type }}</td>
              <td>
                <button v-show="showAddSpecButton(specification.id)" class="btn-primary btn btn-sm" @click.prevent="add(specification.id)">Add</button>
              </td>
            </tr>
          </template>
          <Pagination :current-page.sync="currentPage" :items-per-page="itemsPerPage" :result-count="resultCount" :total-pages="totalPages"></Pagination>
          </tbody>
        </table>
      </template>

      <template v-slot:footer>
      </template>
    </modal>
  </div>
</template>

<script>

import Modal from "./Modal";
import {Datetime} from 'vue-datetime';
import Summernote from "./plugins/Summernote";
import Pagination from "./Pagination";

export default {
  components: {
    Pagination,
    'modal':      Modal,
    'datetime':   Datetime,
    'summernote': Summernote,
  },
  props:      {
    specifications:    {
      type: Object
    },
    items:             {
      type: Array
    },
    field:             {
      type: String
    },
    translationsField: {
      type: Boolean
    },
    categoryId:        {
      type:    Number,
      default: null
    },
    translating:       {
      type:    Boolean,
      default: false,
    },
    locale:            {
      type: String,
    },
    arrayTypes:        {
      type:    Array,
      default: () => ([
        'key-value-pairs',
        'key-value-pairs-multiselect',
        'multiselect',
        'products',
      ]),
    }
  },
  data() {
    return {
      chosenSpecifications: {},
      isModalVisible:       false,
      unlimited:            [],
      currentPage:          1,
      itemsPerPage:         8,
      resultCount:          0,
    };
  },
  created() {
    this.chosenSpecifications = [];

    // Add default specifications
    if (this.categoryId && !this.translating) {
      for (let [i, specification] of Object.entries(this.specifications)) {
        let current = this.items.find(value => value.id === specification.id);
        if (current || !specification.product_categories) {
          continue;
        }

        let categoryIds = specification.product_categories.map((category) => category.id);
        if (categoryIds.indexOf(this.categoryId) !== -1) {
          this.chosenSpecifications.push(specification);
        }
      }
    }
    for (let [i, spec] of Object.entries(this.items)) {
      this.chosenSpecifications.push(spec);
    }

    // Set default values
    for (let [i, spec] of Object.entries(this.chosenSpecifications)) {
      if (!spec._joinData) {
        spec._joinData = {
          data: null
        };
      }
      if (!spec._joinData.data && this.arrayTypes.indexOf(spec.type) !== -1) {
          spec._joinData.data = [];
      }
    }
  },
  computed: {
    totalPages:              function() {
      return Math.ceil(this.resultCount / this.itemsPerPage)
    },
    paginatedSpecifications: function() {
      let specifications = Object.values(this.specifications);
      if (!specifications) {
        return [];
      }
      this.resultCount = specifications.length

      if (this.currentPage >= this.totalPages) {
        this.currentPage = this.totalPages
      }
      const index = this.currentPage * this.itemsPerPage - this.itemsPerPage;
      return specifications.slice(index, index + this.itemsPerPage)
    }
  },
  methods:  {
    unlimitedClicked(index) {
      if (this.unlimited[index]) {
        this.chosenSpecifications[index]._joinData.data = 999999;
      } else {
        this.chosenSpecifications[index]._joinData.data = null;
      }
    },
    /**
     * @param {int} index
     * @param {boolean|string} multiple
     * @returns {string}
     */
    getFieldName(index, multiple = false) {
      let fieldName = '';
      if (!this.translating || !this.translationsField) {
        fieldName = this.field + '[' + index + '][_joinData][data]';
      } else {
        fieldName = this.field + '[' + index + '][_joinData][_translations][' + this.locale + '][data]';
      }
      if (multiple === true) {
        fieldName += '[]'
      } else if (multiple !== false) {
        // Specified key
        fieldName += '[' + multiple + ']';
      }
      return fieldName;
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    add(specificationId) {
      let data = null;
      let specification = this.specifications[specificationId];
      if (this.arrayTypes.indexOf(specification.type) !== -1) {
        data = [];
      }
      this.chosenSpecifications.push({id: specificationId, _joinData: {data: data}});
    },
    remove(specificationId) {
      this.chosenSpecifications = this.chosenSpecifications.filter(value => value.id !== specificationId);
    },
    showAddSpecButton(specificationId) {
      return !this.chosenSpecifications.find(value => value.id === specificationId);
    },
    isDeletable(categories) {
      return categories.filter(category => category.id === this.categoryId).length === 0;
    },
  },
}
</script>
