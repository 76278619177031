var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-select",
    _vm._g(
      _vm._b(
        { attrs: { value: _vm.value, options: _vm.options } },
        "b-form-select",
        _vm.$attrs,
        false
      ),
      _vm.inputListeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }