var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: [{ "is-invalid": Object.keys(_vm.errors).length > 0 }] },
    [
      _vm.pairs.length
        ? _c("div", { staticClass: "row mb-1" }, [
            _c("div", { class: _vm.translating ? "col-md-6" : "col-md-5" }, [
              _vm._v("\n      Key\n    "),
            ]),
            _vm._v(" "),
            _c("div", { class: _vm.translating ? "col-md-6" : "col-md-5" }, [
              _vm._v("\n      Value\n    "),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.pairs, function (pair, index) {
        return _c(
          "div",
          { staticClass: "form-group row justify-content-end" },
          [
            _vm.translating
              ? _c("div", { staticClass: "col-md-6" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: pair.key,
                        expression: "pair.key",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "hidden",
                      name: _vm.inputName(index, "key"),
                    },
                    domProps: { value: pair.key },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(pair, "key", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: pair.key,
                        expression: "pair.key",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", disabled: "" },
                    domProps: { value: pair.key },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(pair, "key", $event.target.value)
                      },
                    },
                  }),
                ])
              : _c("div", { staticClass: "col-md-5" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: pair.key,
                        expression: "pair.key",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { type: "text", name: _vm.inputName(index, "key") },
                    domProps: { value: pair.key },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(pair, "key", $event.target.value)
                      },
                    },
                  }),
                ]),
            _vm._v(" "),
            _c("div", { class: _vm.translating ? "col-md-6" : "col-md-5" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: pair.value,
                    expression: "pair.value",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text", name: _vm.inputName(index, "value") },
                domProps: { value: pair.value },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(pair, "value", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            !_vm.translating
              ? _c("div", { staticClass: "col-md-2" }, [
                  _vm.pairs.length > 1
                    ? _c("i", {
                        staticClass: "fas fa-trash",
                        on: {
                          click: function ($event) {
                            return _vm.deletePair(index)
                          },
                        },
                      })
                    : _vm._e(),
                ])
              : _vm._e(),
          ]
        )
      }),
      _vm._v(" "),
      !_vm.translating
        ? _c(
            "button",
            {
              staticClass: "btn btn-primary btn-sm float-right",
              attrs: { type: "button" },
              on: {
                click: function ($event) {
                  return _vm.addNewPair()
                },
              },
            },
            [_c("i", { staticClass: "fas fa-plus" })]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }