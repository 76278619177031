var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("section", { staticClass: "card" }, [
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c(
              "b-container",
              { staticClass: "composer-products" },
              [
                _c("h2", [_vm._v("Product Selection")]),
                _vm._v(" "),
                _vm._l(_vm.filters, function (filter, index) {
                  return _c("product-selection", {
                    key: index,
                    attrs: {
                      filters: _vm.filters,
                      filter: filter,
                      brands: _vm.brands,
                      categories: _vm.categories,
                    },
                    on: {
                      "add-product-filter": function ($event) {
                        return _vm.addProductFilter()
                      },
                      "product-selected": function ($event) {
                        return _vm.updateUrl()
                      },
                    },
                  })
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "b-container",
              { staticClass: "composer-products" },
              [
                _c("h2", [_vm._v("Filters")]),
                _vm._v(" "),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c("FormSelect", {
                          attrs: { id: "region", options: _vm.regions },
                          model: {
                            value: _vm.region,
                            callback: function ($$v) {
                              _vm.region = $$v
                            },
                            expression: "region",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("br"),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.filters.length > 0
              ? _c(
                  "b-container",
                  { staticClass: "composer-evaluate" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-danger add-row",
                        on: {
                          click: function ($event) {
                            return _vm.evaluate()
                          },
                        },
                      },
                      [_vm._v("Evaluate")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { "data-loader": "" } },
              [
                _vm._m(1),
                _vm._v(" "),
                _vm.valid !== null
                  ? _c(
                      "b-container",
                      { staticClass: "composer-proposition" },
                      [
                        _c("h2", [_vm._v("Matching Conditionals")]),
                        _vm._v(" "),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _vm.valid
                                  ? _c(
                                      "b-alert",
                                      {
                                        attrs: { show: "", variant: "success" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  This combination of products is a valid proposition.\n                "
                                        ),
                                      ]
                                    )
                                  : !_vm.valid
                                  ? _c(
                                      "b-alert",
                                      {
                                        attrs: { show: "", variant: "danger" },
                                      },
                                      [
                                        _c(
                                          "ul",
                                          _vm._l(_vm.errors, function (error) {
                                            return _c("li", [
                                              _c("span", {
                                                domProps: {
                                                  innerHTML: _vm._s(error),
                                                },
                                              }),
                                            ])
                                          }),
                                          0
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.valid
                  ? _c(
                      "b-container",
                      [
                        _c("h2", [_vm._v("Proposition JSON")]),
                        _vm._v(" "),
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c("object-inspector", {
                                  attrs: { data: _vm.propositionJson },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", { staticClass: "card-title mb-4" }, [
      _c("i", { staticClass: "fa fa-copyright" }),
      _vm._v(" Proposition Composer"),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loader" }, [
      _c("div", { staticClass: "loader-icon" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }