var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-auto" },
    [
      _c(
        "b-form-group",
        [
          _c(
            "b-form",
            { attrs: { inline: "" } },
            [
              _vm.showDelete
                ? _c(
                    "b-button",
                    {
                      staticClass: "btn-sm delete-row icon-only mr-2",
                      attrs: { variant: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("delete-button-clicked")
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-minus-circle" })]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.mutator === false
                ? _c("b-form-select", {
                    staticClass: "mb-2 mr-sm-2 mb-sm-0",
                    attrs: { id: "if", options: _vm.models },
                    model: {
                      value: _vm.model,
                      callback: function ($$v) {
                        _vm.model = $$v
                      },
                      expression: "model",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.specifications.length > 0 && _vm.mutator === false
                ? _c("b-form-select", {
                    staticClass: "mb-2 mr-sm-2 mb-sm-0",
                    attrs: {
                      id: "specifications",
                      options: _vm.specifications,
                    },
                    model: {
                      value: _vm.specification,
                      callback: function ($$v) {
                        _vm.specification = $$v
                      },
                      expression: "specification",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.model !== "combination" && _vm.mutator === false
                ? _c("b-form-select", {
                    staticClass: "mb-2 mr-sm-2 mb-sm-0",
                    attrs: {
                      id: "if",
                      options: [
                        { value: "is", text: "is" },
                        { value: "is not", text: "is not" },
                      ],
                    },
                    model: {
                      value: _vm.expression,
                      callback: function ($$v) {
                        _vm.expression = $$v
                      },
                      expression: "expression",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.entities.length > 0
                ? _c("b-form-select", {
                    staticClass: "mb-2 mr-sm-2 mb-sm-0",
                    attrs: {
                      id: "entities",
                      multiple: _vm.consequence === true,
                      options: _vm.entities,
                    },
                    model: {
                      value: _vm.entity,
                      callback: function ($$v) {
                        _vm.entity = $$v
                      },
                      expression: "entity",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.specification
                ? _c("b-form-input", {
                    staticClass: "mb-2 mr-sm-2 mb-sm-0",
                    attrs: { id: "specification-value" },
                    model: {
                      value: _vm.specificationValue,
                      callback: function ($$v) {
                        _vm.specificationValue = $$v
                      },
                      expression: "specificationValue",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }