<template>
    <b-row class="mb-4">
        <b-col>
            <FormSelect id="product-brand" :options="brands" v-model="filter.brand"></FormSelect>
        </b-col>
        <b-col>
            <FormSelect id="product-category" :options="categories" v-model="filter.category"></FormSelect>
        </b-col>
        <b-col v-if="filter.category">
            <FormSelect id="product" :options="products[index]" v-model="filter.product"></FormSelect>
        </b-col>
        <b-col v-if="filter.product && (index + 1) === filters.length">
            <b-button class="btn-primary add-row" @click="addProductFilter">
                <span><i class="fa fa-plus-circle"></i> Add Product</span>
            </b-button>
        </b-col>
    </b-row>
</template>

<script>
import Vue from "vue";
import FormSelect from "../FormElements/FormSelect";

export default {
    name:       'product-selection',
    components: {
        FormSelect,
    },
    props:      {
        filters:    {
            type:     Array,
            required: true
        },
        filter:     {
            type:     Object,
            required: true
        },
        brands:     {
            type:     Array,
            required: true
        },
        categories: {
            type:     Array,
            required: true
        },
    },
    data() {
        return {
            products: [],
        }
    },
    created() {
        if (this.filter.brand && this.filter.category) {
            this.updateProducts(this.index);
        }
    },
    computed: {
        index() {
            return this.$vnode.key;
        },
    },
    methods:  {
        addProductFilter() {
            this.$emit('add-product-filter');
        },
        updateProducts(index) {
            $.ajax({
                url:  '/products/filter',
                data: this.filters[index],
                type: 'POST',
            }).done(response => {
                Vue.set(this.products, index, response.products);
            }).fail((xhr) => {
                this.$bvToast.toast('Products couldn\'t be updated', {
                    title:         'Something went wrong',
                    variant:       'danger',
                    autoHideDelay: 5000,
                    appendToast:   false,
                })
            });
        },
    },
    watch:    {
        'filter.brand':    function(newValue, oldValue) {
            // Reset category and product
            this.filter.category = null;
            this.filter.product  = null;
        },
        'filter.category': function(newValue, oldValue) {
            // Reset product and update list
            this.filter.product = null;
            this.updateProducts(this.index);
        },
        'filter.product': function() {
            this.$emit('product-selected');
        }
    }
}
</script>
