<template>
    <b-form-select v-bind="$attrs" v-bind:value="value" v-on="inputListeners" :options="options"></b-form-select>
</template>

<script>
export default {
    name:         'form-select',
    props:        ['id', 'options', 'value'],
    inheritAttrs: false,
    computed:     {
        inputListeners() {
            let vm = this;
            return Object.assign({}, this.$listeners, {
                    // Make sure v-model works with the component
                    input: (inputValue) => {
                        vm.$emit('input', inputValue)
                    }
                }
            )
        },
    }
}
</script>
