var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    { staticClass: "mb-4" },
    [
      _c(
        "b-col",
        [
          _c("FormSelect", {
            attrs: { id: "product-brand", options: _vm.brands },
            model: {
              value: _vm.filter.brand,
              callback: function ($$v) {
                _vm.$set(_vm.filter, "brand", $$v)
              },
              expression: "filter.brand",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-col",
        [
          _c("FormSelect", {
            attrs: { id: "product-category", options: _vm.categories },
            model: {
              value: _vm.filter.category,
              callback: function ($$v) {
                _vm.$set(_vm.filter, "category", $$v)
              },
              expression: "filter.category",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.filter.category
        ? _c(
            "b-col",
            [
              _c("FormSelect", {
                attrs: { id: "product", options: _vm.products[_vm.index] },
                model: {
                  value: _vm.filter.product,
                  callback: function ($$v) {
                    _vm.$set(_vm.filter, "product", $$v)
                  },
                  expression: "filter.product",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.filter.product && _vm.index + 1 === _vm.filters.length
        ? _c(
            "b-col",
            [
              _c(
                "b-button",
                {
                  staticClass: "btn-primary add-row",
                  on: { click: _vm.addProductFilter },
                },
                [
                  _c("span", [
                    _c("i", { staticClass: "fa fa-plus-circle" }),
                    _vm._v(" Add Product"),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }