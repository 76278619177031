var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: "col-auto" + (_vm.repeatable ? " ml-5" : "") },
    [
      _c(
        "b-form",
        { attrs: { inline: "" } },
        [
          _vm.repeatable
            ? _c(
                "b-button",
                {
                  staticClass: "btn-sm mr-2 delete-row icon-only",
                  attrs: { variant: "danger" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("delete-button-clicked")
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-minus-circle" })]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("label", { staticClass: "mr-sm-2", attrs: { for: "logic" } }, [
            _vm._v("If"),
          ]),
          _vm._v(" "),
          _c("b-form-select", {
            staticClass: "mb-2 mr-sm-2 mb-sm-0",
            attrs: {
              id: "logic",
              options: [
                { value: "AND", text: "All" },
                { value: "OR", text: "Any" },
              ],
            },
            model: {
              value: _vm.logic,
              callback: function ($$v) {
                _vm.logic = $$v
              },
              expression: "logic",
            },
          }),
          _vm._v(" "),
          _c("label", { staticClass: "mr-sm-2", attrs: { for: "negate" } }, [
            _vm._v("of the conditions are true"),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-form",
        [
          _vm.repeatable
            ? _c(
                "b-button",
                {
                  staticClass: "btn-sm icon-only ml-3",
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.addChild()
                    },
                  },
                },
                [_c("i", { staticClass: "fa fa-plus-circle" })]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }