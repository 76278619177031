/**
 * Main entry point for the bundled script
 */
import ui from "./src/ui";
import Vue from 'vue';
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import {Datetime} from 'vue-datetime';
import vSelect from 'vue-select';
import Assets from './components/Assets';
import AddConditional from './components/AddConditional';
import AddConditionalWidget from './components/AddConditionalWidget';
import Composer from './components/Composer';
import DualList from './components/DualList';
import Modal from "./components/Modal";
import Specifications from "./components/Specifications";
import PasswordStrength from "./components/PasswordStrength";
import Summernote from "./components/plugins/Summernote";
import ObjectInspector from 'vue-object-inspector';
import KeyValueMultiselect from "./components/Specifications/KeyValueMultiselect";

// Include scss file to ensure it is included in the build
require('../style/bundle.scss');

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

if (window.dynamo._csrfToken) {
    $.ajaxSetup({
        headers: {
            'X-CSRF-Token': window.dynamo._csrfToken
        }
    });
}

const app = new Vue({
    el:         '#app',
    components: {
        'assets':                Assets,
        'addConditional':        AddConditional,
        'addConditionalWidget':  AddConditionalWidget,
        'composer':              Composer,
        'datetime':              Datetime,
        'dualList':              DualList,
        'modal':                 Modal,
        'passwordStrength':      PasswordStrength,
        'specifications':        Specifications,
        'summernote':            Summernote,
        'v-select':              vSelect,
        'objectInspector':       ObjectInspector,
        'key-value-multiselect': KeyValueMultiselect,
    },
    data:       () => ({
        // Global variables for plugins
        passwordStrengthValue: null, // Placeholder for password-strength-checker
    }),
    mounted() {
        // Initialize the UI
        ui.initialize();
    },
});

