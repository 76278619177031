<template>
  <div class="row">
    <div class="col">
      <h2 class="card-title mb-4"><i class="fa fa-copyright"></i>&nbsp;Proposition Composer</h2>
      <section class="card">
        <div class="card-body">
          <b-container class="composer-products">
            <h2>Product Selection</h2>
            <product-selection
                :key="index"
                :filters="filters"
                :filter="filter"
                :brands="brands"
                :categories="categories"
                @add-product-filter="addProductFilter()"
                @product-selected="updateUrl()"
                v-for="(filter, index) in filters"
            ></product-selection>
          </b-container>
          <br/>
          <b-container class="composer-products">
            <h2>Filters</h2>
            <b-row>
              <b-col>
                <FormSelect id="region" :options="regions" v-model="region"></FormSelect>
              </b-col>
              <!--                <b-col>-->
              <!--                    <FormSelect id="partner" :options="partners" v-model="partner"></FormSelect>-->
              <!--                </b-col>-->
              <br/>
              <br/>
            </b-row>
          </b-container>
          <b-container v-if="filters.length > 0" class="composer-evaluate">
            <b-button class="btn-danger add-row" @click="evaluate()">Evaluate</b-button>
          </b-container>
          <br/>
          <div data-loader>
            <div class="loader">
              <div class="loader-icon"></div>
            </div>
            <b-container v-if="valid !== null" class="composer-proposition">
              <h2>Matching Conditionals</h2>
              <b-row>
                <b-col>
                  <b-alert show v-if="valid" variant="success">
                    This combination of products is a valid proposition.
                  </b-alert>
                  <b-alert show v-else-if="!valid" variant="danger">
                    <ul>
                      <li v-for="error in errors">
                        <span v-html="error"></span>
                      </li>
                    </ul>
                  </b-alert>
                </b-col>
              </b-row>
            </b-container>
            <b-container v-if="valid">
              <h2>Proposition JSON</h2>
              <b-row>
                <b-col>
                  <object-inspector :data="propositionJson"></object-inspector>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import FormSelect from "./FormElements/FormSelect";
import ProductSelection from "./Composer/ProductSelection";
import ObjectInspector from "vue-object-inspector";

export default {
  name:       'composer',
  props:      {
    brands:              {
      type:     Array,
      required: true
    },
    categories:          {
      type:     Array,
      required: true
    },
    regions:             {
      type:     Array,
      required: true
    },
    preSelectedProducts: {
      type:     Array,
      required: false
    },
    composerUrl:         {
      type:     String,
      required: true
    },
    csrfToken:           {
      type:     String,
      required: true
    },
  },
  components: {
    FormSelect,
    ProductSelection,
    ObjectInspector,
  },
  data() {
    return {
      conditionals:     [],
      filters:          [{brand: null, category: null, product: null}],
      valid:            null,
      region:           null,
      errors:           [],
      propositionJson:  {},
    }
  },
  created() {
    if (this.preSelectedProducts.length > 0) {
      this.filters = [];
      this.preSelectedProducts.forEach((product) => {
        let filter = {brand: product.brand_id, category: product.category_key, product: product.id};
        this.filters.push(filter);
      });

      this.addProductFilter();
      this.evaluate();
    }
  },
  methods: {
    addProductFilter() {
      this.filters.push({brand: null, category: null, product: null});
    },
    evaluate() {
      this.valid = null;
      $('[data-loader]', $(this.$el)).addClass('loading');
      if (this.filters.length === 0) {
        this.$bvToast.toast('You haven\'t chosen any product yet. Please select at least one.', {
          title:         'Select products first',
          variant:       'danger',
          autoHideDelay: 5000,
          appendToast:   false,
        })
        return;
      }
      $.ajax({
        url:  '/propositions/evaluate',
        data: {'filters': this.filters, 'region': this.region},
        type: 'POST',
      }).done(response => {
        this.valid = response.valid;
        this.errors = response.errors;
        this.propositionJson = response.propositionJson;
        $('[data-loader]', $(this.$el)).removeClass('loading');
      }).fail((xhr) => {
        this.$bvToast.toast('Products couldn\'t be evaluated', {
          title:         'Something went wrong',
          variant:       'danger',
          autoHideDelay: 5000,
          appendToast:   false,
        });
        $('[data-loader]', $(this.$el)).removeClass('loading');
      });
    },
    updateUrl: function() {
      if (!window.history) {
        return;
      }

      let url = this.composerUrl;
      let productIds = [];
      this.filters.forEach((filter) => {
        if (filter.product) {
          productIds.push(filter.product);
        }
      });
      if (productIds) {
        url = url + '/' + productIds.join('-');
      }

      window.history.pushState({}, null, url);
    }
  },
}
</script>
